import easterLoginImage from "./images/easter-login-bg.jpg";
import loginImage from "./images/login-bg.png";
import giftImage from "./images/gift.png";
import easterGiftImage from "./images/easter-gift.png";
import christmasGiftImage from "./images/christmas-gift.png";

const IS_PROD_ENV = process.env.REACT_APP_ENV === "production" ? true : false;
const IS_LOCAL_ENV = process.env.REACT_APP_ENV === "local" ? true : false;
const API_URL = IS_PROD_ENV
  ? "https://api-v2.swipestox.com"
  : "https://api-v2.sxdev.io";
const MAGIC_SALT = "$2a$10$0.o/S/w/i/p/e/S/A/L/T/";
const ACCEPT_VERSION = {
  ONE: "1.*",
  TWO: "2.*",
  THREE: "3.*",
  THREE_ZERO: "3.0",
  THREE_ONE: "3.1",
};
const PLATFORM = "web-store";
const DOCUMENT_TITLE = "Loyalty Store";
const APP_LANGUAGES = {
  en: {
    CODE: "en",
    TEXT: "English",
    FLAG: "gb",
  },
  es: {
    CODE: "es",
    TEXT: "Español",
    FLAG: "es",
  },
  de: {
    CODE: "de",
    TEXT: "Deutsch",
    FLAG: "de",
  },
  pl: {
    CODE: "pl",
    TEXT: "Polski",
    FLAG: "pl",
  },
  cs: {
    CODE: "cs",
    TEXT: "Česky",
    FLAG: "cz",
  },
  it: {
    CODE: "it",
    TEXT: "Italiano",
    FLAG: "it",
  },
  hu: {
    CODE: "hu",
    TEXT: "Magyar",
    FLAG: "hu",
  },
  pt: {
    CODE: "pt",
    TEXT: "Português",
    FLAG: "pt",
  },
  ms: {
    CODE: "ms",
    TEXT: "Malay",
    FLAG: "my",
  },
  hi: {
    CODE: "hi",
    TEXT: "हिन्दी",
    FLAG: "in",
  },
  ur: {
    CODE: "ur",
    TEXT: "اردو",
    FLAG: "pk",
  },
  vi: {
    CODE: "vi",
    TEXT: "Tiếng Việt",
    FLAG: "vn",
  },
  id: {
    CODE: "id",
    TEXT: "Bahasa Indonesia",
    FLAG: "id",
  },
  th: {
    CODE: "th",
    TEXT: "ภาษาไทย",
    FLAG: "th",
  },
  "zh-hans": {
    CODE: "zh-hans",
    TEXT: "汉语",
    FLAG: "cn",
  },
};
const TRUST_PILOT_TEMPLATE_ID = "5419b6ffb0d04a076446a9af";
const TRUST_PILOT_BUSINESS_UNIT_ID = "5d6f89c3a6a34700013e5b01";
const TRUST_PILOT_NAGA_URL = "https://www.trustpilot.com/review/naga.com";
const RE_CAPTCHA_KEY = "6LeJxBscAAAAADEWuxE7MV1RZmQri9eqsZ6kkCvM";
const USER_MEDIA_DEV = "https://s3-eu-west-1.amazonaws.com/sxphotoresized";
const USER_MEDIA_PROD =
  "https://s3-eu-west-1.amazonaws.com/swipestoxphotoresized";
const PHOTO_PROFILE_URL = `${
  IS_PROD_ENV ? USER_MEDIA_PROD : USER_MEDIA_DEV
}/profile/thumbnail/`;
const QUERY_NAMES = {
  userInfo: "userInfo",
  loyaltyGroups: "loyaltyGroups",
  loyaltyProduct: "loyaltyProduct",
  loyaltyBalance: "loyaltyBalance",
  autologinUrl: "autologinUrl",
  allCountries: "allCountries",
  excludedDeliveryCountries: "excludedDeliveryCountries",
};
const SENTRY_DSN =
  "https://d7e3b4248af44f248034e296cc433889@o1059365.ingest.sentry.io/6047883";
const ASSETS_BUCKET_URL = "https://content.swipestox.com/store_images";
const AUTOLOGIN_URL_DESTINATIONS = {
  WEB_TRADER: "web",
};
const AUTOLOGIN_PAGE_DESTINATIONS = {
  FUNDING: "funding",
};

const INTERCOM_APP_ID = "uzsmlojy";

const IS_EASTER_CAMPAIGN = false;
const IS_CHRISTMAS_CAMPAIGN = true;
const LOGIN_BG_IMG = IS_EASTER_CAMPAIGN ? easterLoginImage : loginImage;
const GIFT_IMG = IS_CHRISTMAS_CAMPAIGN ? christmasGiftImage : (IS_EASTER_CAMPAIGN ? easterGiftImage : giftImage);
const MAX_PROGRESS = IS_CHRISTMAS_CAMPAIGN ? 250000 : (IS_EASTER_CAMPAIGN ? 65000 : 50000);
const TERMS_AND_CONDITIONS = "https://files.naga.com/Loyalty%20Store%20Terms%20and%20Conditions.pdf";

const EXPORTS = {
  IS_PROD_ENV,
  IS_LOCAL_ENV,
  API_URL,
  MAGIC_SALT,
  ACCEPT_VERSION,
  PLATFORM,
  DOCUMENT_TITLE,
  APP_LANGUAGES,
  TRUST_PILOT_TEMPLATE_ID,
  TRUST_PILOT_BUSINESS_UNIT_ID,
  TRUST_PILOT_NAGA_URL,
  RE_CAPTCHA_KEY,
  PHOTO_PROFILE_URL,
  QUERY_NAMES,
  SENTRY_DSN,
  ASSETS_BUCKET_URL,
  AUTOLOGIN_URL_DESTINATIONS,
  AUTOLOGIN_PAGE_DESTINATIONS,
  INTERCOM_APP_ID,
  IS_EASTER_CAMPAIGN,
  IS_CHRISTMAS_CAMPAIGN,
  LOGIN_BG_IMG,
  MAX_PROGRESS,
  GIFT_IMG,
  TERMS_AND_CONDITIONS,
};

export default EXPORTS;
