import React from "react";
import {
  Flex,
  Box,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Container,
  useColorModeValue,
  useToast,
  Image,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import * as R from "ramda";

import Header from "./Header";
import Footer from "./Footer";
import PasswordInput from "../common/PasswordInput";

import utils from "../../utils";
import api from "../../api";
import state from "../../state";
import constants from "../../constants";

import { LoginOutput } from "../../api/apiTypes";

import giftImage from "../../images/gift.png";

const defaultValues = {
  username: "",
  password: "",
};

type ErrorData = {
  info: {
    message: string;
  };
};

function SignIn() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();
  const toastIdRef = React.useRef<string | number | undefined>();
  const schema = Yup.object({
    username: Yup.string().required(t("AUTH.EMAIL_OR_USERNAME_IS_REQUIRED")),
    password: Yup.string().required(t("AUTH.PASSWORD_IS_REQUIRED")),
  }).required();
  const form = useForm({ defaultValues, resolver: yupResolver(schema) });
  const bg = useColorModeValue("#fff", "#131723");
  const loginMutation = useMutation(api.mutations.login, {
    onSuccess,
    onError,
  });
  const useAuth = state.useAuth();
  const history = useHistory();
  const [recaptcha, setRecaptcha] = React.useState<string | null>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  React.useEffect(() => {
    async function getRecaptcha() {
      const recaptcha = R.isNil(executeRecaptcha)
        ? null
        : await executeRecaptcha("LOGIN");
      setRecaptcha(recaptcha);
    }
    getRecaptcha();
  }, [executeRecaptcha]);

  function onError(data: AxiosError<ErrorData>) {
    const status = data.response?.status;
    const message = data.response?.data.info.message;

    if (message === "Non-global users can't access loyalty store.") {
      toastIdRef.current = toast({
        title: t("ERROR"),
        description: t("AUTH.NON_GLOBAL_USERS_CANT_ACCESS_LOYALTY_STORE"),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    switch (status) {
      case 450:
        toastIdRef.current = toast({
          title: t("ERROR"),
          description: t("AUTH.INCORRECT_USERNAME_OR_PASSWORD"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      default:
        toastIdRef.current = toast({
          title: t("ERROR"),
          description: t("SOMETHING_WENT_WRONG"),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
    }
  }

  function onSuccess(data: LoginOutput) {
    const username = data.data.data.user_name;
    const userId = data.data.data.user_id;

    queryClient.setQueryData(constants.QUERY_NAMES.userInfo, {
      data: { data: { user_name: username, user_id: userId } },
    });
    useAuth.setToken(data.data.info.token);
    useAuth.setXsrf(data.data.info.xsrf);
    useAuth.setIntercomHash(data.data.data.intercom_hash);
    toast.closeAll();
    if (data.data.data.mfa_enabled) {
      return history.push("/mfa");
    }
    useAuth.setIsAuthorized(true);
    return history.push("/");
  }

  async function onSubmit(values: { username: string; password: string }) {
    const device_uuid = await utils.fingerprint.getFingerprint();
    const password = utils.crypto.encode(values.password);
    const data = {
      device_uuid,
      g_recaptcha_version: 3,
      "g-recaptcha-response": recaptcha,
      get_user_info: false,
      password,
      user_name: values.username,
    };
    loginMutation.mutate(data);
  }

  return (
    <Flex
      backgroundImage={constants.LOGIN_BG_IMG}
      flexDirection="column"
      minHeight="100vh"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      paddingBottom="42px"
    >
      <Header />
      <Flex flexGrow={1} alignItems="center">
        <Container maxW="container.xl">
          <Flex
            justifyContent={{ base: "center", lg: "initial" }}
            alignItems={{ lg: "flex-end" }}
          >
            <Box bg={bg} borderRadius="2xl" maxW="496px" padding="32px">
              <Heading mb="26px">
                {t("AUTH.SIGN_IN_TO_YOUR_NAGA_LOYALTY_STORE_ACCOUNT")}
              </Heading>
              <Box as="form" onSubmit={form.handleSubmit(onSubmit)} mb="48px">
                <FormControl
                  isInvalid={Boolean(form.formState.errors.username)}
                  mb="20px"
                >
                  <FormLabel>{t("AUTH.EMAIL_OR_USERNAME")}</FormLabel>
                  <Input
                    {...form.register("username")}
                    variant="filled"
                    height="48px"
                    borderRadius="lg"
                  />
                  <FormHelperText>
                    {form.formState.errors.username?.message}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  isInvalid={Boolean(form.formState.errors.password)}
                  mb="20px"
                >
                  <FormLabel>{t("AUTH.PASSWORD")}</FormLabel>
                  <PasswordInput
                    {...form.register("password")}
                    variant="filled"
                    height="48px"
                    borderRadius="lg"
                  />
                  <FormHelperText>
                    {form.formState.errors.password?.message}
                  </FormHelperText>
                </FormControl>
                <Button
                  isLoading={loginMutation.isLoading}
                  type="submit"
                  height="48px"
                  isFullWidth
                  colorScheme="blue"
                  borderRadius="lg"
                >
                  {t("AUTH.SIGN_IN")}
                </Button>
              </Box>
              <Footer />
            </Box>
            {!constants.IS_EASTER_CAMPAIGN && (
              <Box>
                <Image
                  src={giftImage}
                  display={{ base: "none", lg: "initial" }}
                  alt=""
                />
              </Box>
            )}
          </Flex>
        </Container>
      </Flex>
    </Flex>
  );
}

export default SignIn;
