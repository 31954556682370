import Select, { SingleValue } from "react-select";
import { useTranslation } from "react-i18next";
import * as R from "ramda";
import { ChevronDown } from "react-feather";

import constants from "../../constants";

const styles = {
  control: (provided: {}) => ({
    ...provided,
    backgroundColor: "transparent",
    borderColor: "transparent",
    width: 100,
  }),
  singleValue: (provided: {}) => ({
    ...provided,
    color: "#fff",
  }),
  placeholder: (provided: {}) => ({
    ...provided,
    color: "#fff",
  }),
};

function LanguagePicker() {
  const { t, i18n } = useTranslation();
  const options = R.map(
    (item) => ({
      value: item.CODE,
      label: item.TEXT,
    }),
    R.values(constants.APP_LANGUAGES)
  );
  const value = R.find(
    (item) => item.value === R.toLower(i18n.language),
    options
  );

  function handleOnChange(
    newValue: SingleValue<{ value: string; label: string }>
  ) {
    i18n.changeLanguage(newValue?.value);
  }

  return (
    <Select
      placeholder={t("SELECT")}
      value={value}
      options={options}
      styles={styles}
      onChange={handleOnChange}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: () => <ChevronDown size={16} color="#fff" />,
      }}
    />
  );
}

export default LanguagePicker;
