import create from "zustand";

function parse(key: string, defaultValue: boolean) {
  try {
    const text = localStorage.getItem(key);
    if (text) {
      return JSON.parse(text);
    }
    return defaultValue;
  } catch {
    return defaultValue;
  }
}

const useAuth = create((set) => ({
  token: localStorage.getItem("token"),
  xsrf: localStorage.getItem("xsrf"),
  isAuthorized: parse("isAuthorized", false),
  intercomHash: localStorage.getItem("INTERCOM_HASH"),
  setToken: (token: string) => {
    localStorage.setItem("token", token);
    return set({ token });
  },
  setXsrf: (xsrf: string) => {
    localStorage.setItem("xsrf", xsrf);
    return set({ xsrf });
  },
  setIsAuthorized: (isAuthorized: boolean) => {
    localStorage.setItem("isAuthorized", String(isAuthorized));
    return set({ isAuthorized });
  },
  setIntercomHash: (hash: string) => {
    localStorage.setItem("INTERCOM_HASH", hash);
    return set({ hash });
  },
  logOut: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("xsrf");
    localStorage.removeItem("isAuthorized");
    localStorage.removeItem("INTERCOM_HASH");
    set({ token: "" });
    set({ xsrf: "" });
    set({ isAuthorized: "" });
    set({ intercomHash: "" });
  },
}));

const EXPORTS = {
  useAuth,
};

export default EXPORTS;
