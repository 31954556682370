import {
  Container,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { ChevronLeft } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../store/Header";

const NUMBER_OF_CONDITIONS = [...Array(20)];

function TermsAndConditions() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Flex flexDir="column">
      <Header isDark />
      <Container maxW="container.lg">
        <Link to="#" onClick={history.goBack}>
          <Flex display="inline-flex" alignItems="center" mb="20px">
            <ChevronLeft />
            <Text marginLeft="4px">{t("BACK")}</Text>
          </Flex>
        </Link>
        <Text fontSize="32px" mb="20px" fontWeight={700}>
          {t("STORE.TERMS_AND_CONDITIONS")}
        </Text>
        <UnorderedList mb="30px">
          {NUMBER_OF_CONDITIONS.map((_, key) => (
            <ListItem>{t(`STORE.TERMS_AND_CONDITIONS_${key + 1}`)}</ListItem>
          ))}
        </UnorderedList>
      </Container>
    </Flex>
  );
}

export default TermsAndConditions;
