import { UserInfoOutput } from "../api/apiTypes";

export const getIntercomConfig = (options: UserInfoOutput) => {
  const userInfo = options?.data?.data;
  return {
    name: userInfo.first_name || userInfo.user_name,
    email: userInfo.email,
    userId: String(userInfo.user_id),
    userHash: localStorage.getItem("INTERCOM_HASH") || undefined,
    createdAt: String(new Date(userInfo.registered_since).getTime()),
  };
};

export const calculatePercentage = (percent: number, total: number) => {
  return percent === 0 ? 0 : Math.floor((percent / total) * 100);
};

type ReplaceMapperGeneralType = {
  [key: string]: string;
};

const replaceMapper: ReplaceMapperGeneralType = {
  "(": "%28",
  ")": "%29",
};

const replaceRegex = new RegExp(Object.keys(replaceMapper).map(a => `\\${a}`).join("|"), "gi");

export const encodeURIComponentAdvanced = (uriPart: string) => {
  return encodeURIComponent(uriPart).replace(
    replaceRegex,
    (matched: string) => replaceMapper[matched] || ""
  );
};
