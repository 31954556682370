import LevelSvg from "./assets/7.svg";

type props = {
  size?: number;
};

function LevelSeven(props: props) {
  const { size = 40, ...rest } = props;
  return <img src={LevelSvg} width={size} alt="Level 6" {...rest}/>;
}

export default LevelSeven;
