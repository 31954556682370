import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignIn from "./features/auth/SignIn";
import StoreItem from "./features/store-item/StoreItem";
import Store from "./features/store/Store";
import Mfa from "./features/auth/Mfa";
import TermsAndConditions from "./features/common/Terms";

import state from "./state";
import ScrollToTop from "./utils/ScrollToTop";

import "./i18n";
import "./global.css";

function App() {
  const isAuthorized = state.useAuth((state) => state.isAuthorized);
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact>
          {isAuthorized ? <Store /> : <SignIn />}
        </Route>
        <Route path="/store/:id">
          <StoreItem />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route path="/login">
          <SignIn />
        </Route>
        <Route path="/mfa">
          <Mfa />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
