import React from "react";
import { Image } from "@chakra-ui/image";
import { Flex, FlexProps, Grid } from "@chakra-ui/layout";
import { isEmpty, map, slice, equals } from "ramda";

type props = {
  images: { image_url: string }[];
  productImage: string;
  setProductImage: (image: string) => void;
} & FlexProps;

function ItemGallery(props: props) {
  const { images, productImage, setProductImage, ...rest } = props;

  React.useEffect(() => {
    if (isEmpty(productImage) && images.length) {
      setProductImage(images[0].image_url);
    }
  }, [setProductImage, productImage, images]);

  return (
    <Flex flexDir="column" {...rest}>
      <Image
        src={productImage}
        alt=""
        borderRadius="xl"
        width="100%"
        mb="20px"
        height="500px"
        objectFit="cover"
        border="1px solid #DCDCDC"
      />
      <Grid
        marginTop="auto"
        gridGap="16px"
        gridAutoRows="100px"
        gridTemplateColumns={["1fr 1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
      >
        {map(
          (item) => (
            <Image
              src={item.image_url}
              borderRadius="xl"
              height="100%"
              width="100%"
              objectFit="cover"
              cursor="pointer"
              borderWidth="2px"
              borderStyle="solid"
              borderColor={
                equals(item.image_url, productImage) ? "#3179FF" : "transparent"
              }
              onClick={() => setProductImage(item.image_url)}
            />
          ),
          slice(0, 4, images)
        )}
      </Grid>
    </Flex>
  );
}

export default ItemGallery;
