import sha256 from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";

import constants from "../constants";

function encode(password: string) {
  return sha256(`${constants.MAGIC_SALT}${password}`).toString(base64);
}

const EXPORTS = {
  encode,
};

export default EXPORTS;
