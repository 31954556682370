import React from "react";
import { useColorMode } from "@chakra-ui/color-mode";
import { Box } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";

import constants from "../../constants";

declare global {
  interface Window {
    Trustpilot: { loadFromElement: (ref: null, e: boolean) => void };
  }
}

function TrustBox() {
  const ref = React.useRef(null);
  const { i18n } = useTranslation();
  const { colorMode } = useColorMode();

  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <Box
      ref={ref}
      data-locale={i18n.language}
      data-theme={colorMode}
      data-template-id={constants.TRUST_PILOT_TEMPLATE_ID}
      data-businessunit-id={constants.TRUST_PILOT_BUSINESS_UNIT_ID}
      className="trustpilot-widget"
    >
      <a href={constants.TRUST_PILOT_NAGA_URL} target="_blank" rel="noreferrer">
        Trustpilot
      </a>
    </Box>
  );
}
export default TrustBox;
