import React from "react";
import { Box, Container, Flex, Grid, Heading, Text } from "@chakra-ui/layout";
import { PinInput, PinInputField } from "@chakra-ui/pin-input";
import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useMutation } from "react-query";
import { FormControl } from "@chakra-ui/form-control";
import { useToast } from "@chakra-ui/toast";
import { Input } from "@chakra-ui/input";
import { useHistory } from "react-router";
import { useController, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Image } from "@chakra-ui/image";

import Header from "./Header";
import Footer from "./Footer";

import api from "../../api";
import state from "../../state";

import { MfaLoginOutput } from "../../api/apiTypes";
import constants from "../../constants";

import giftImage from "../../images/gift.png";

type props = {
  isLoading: boolean;
  onSubmit: (values: { code: string }) => void;
};

function RecoveryCodeForm(props: props) {
  const { isLoading, onSubmit } = props;
  const form = useForm({ defaultValues: { code: "" } });
  const { t } = useTranslation();
  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <FormControl>
        <Input
          {...form.register("code")}
          variant="filled"
          height="48px"
          borderRadius="lg"
          width="100%"
        />
      </FormControl>
      <Button
        type="submit"
        colorScheme="blue"
        isFullWidth
        my="24px"
        isLoading={isLoading}
      >
        {t("SEND")}
      </Button>
    </form>
  );
}

function MfaCodeForm(props: props) {
  const { isLoading, onSubmit } = props;
  const form = useForm({ defaultValues: { code: "" } });
  const { field } = useController({ name: "code", control: form.control });
  const { t } = useTranslation();

  function handleOnComplete(value: string) {
    const values = { code: value };
    onSubmit(values);
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <FormControl>
        <Grid gridTemplateColumns="repeat(6,1fr)" gridGap={["6px", "16px"]}>
          <PinInput
            size="lg"
            value={field.value}
            onComplete={handleOnComplete}
            onChange={(value) => field.onChange(value)}
          >
            <PinInputField w="auto" />
            <PinInputField w="auto" />
            <PinInputField w="auto" />
            <PinInputField w="auto" />
            <PinInputField w="auto" />
            <PinInputField w="auto" />
          </PinInput>
        </Grid>
        <Button
          type="submit"
          colorScheme="blue"
          isFullWidth
          my="24px"
          isLoading={isLoading}
        >
          {t("SEND")}
        </Button>
      </FormControl>
    </form>
  );
}

function Mfa() {
  const toast = useToast();
  const bg = useColorModeValue("#fff", "#131723");
  const loginMfaMutation = useMutation(api.mutations.mfaLogin, {
    onSuccess,
    onError,
  });
  const useAuth = state.useAuth();
  const history = useHistory();
  const [renderRecoveryCode, setRenderRecoveryCode] = React.useState(false);
  const { t } = useTranslation();

  function onError() {
    toast({
      title: t("ERROR"),
      description: t("SOMETHING_WENT_WRONG"),
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  }

  function onSuccess(data: MfaLoginOutput) {
    useAuth.setToken(data.data.info.token);
    useAuth.setXsrf(data.data.info.xsrf);
    useAuth.setIsAuthorized(true);
    history.push("/");
  }

  function handleOnSubmit(values: { code: string }) {
    const data = {
      code: values.code,
    };
    loginMfaMutation.mutate(data);
  }

  return (
    <Flex
      backgroundImage={constants.LOGIN_BG_IMG}
      flexDirection="column"
      minHeight="100vh"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      paddingBottom="42px"
    >
      <Header />
      <Flex flexGrow={1} alignItems="center">
        <Container maxW="container.xl">
          <Flex
            justifyContent={{ base: "center", lg: "initial" }}
            alignItems={{ lg: "flex-end" }}
          >
            <Box
              bg={bg}
              borderRadius="2xl"
              maxW="496px"
              padding="32px"
              width="100%"
            >
              <Heading mb="16px">{t("AUTH.AUTHENTICATION")}</Heading>
              <Text mb="48px">
                {t("AUTH.PLEASE_ENTER_A_6_DIGIT_CODE_FROM_THE_AUTH_APP")}
              </Text>
              {renderRecoveryCode ? (
                <RecoveryCodeForm
                  isLoading={loginMfaMutation.isLoading}
                  onSubmit={handleOnSubmit}
                />
              ) : (
                <MfaCodeForm
                  isLoading={loginMfaMutation.isLoading}
                  onSubmit={handleOnSubmit}
                />
              )}
              {renderRecoveryCode ? (
                <Text mb="48px">
                  {t("AUTH.CANT_USE_RECOVERY_CODE")}{" "}
                  <Text
                    as="span"
                    color="#3179FF"
                    cursor="pointer"
                    onClick={() => setRenderRecoveryCode(false)}
                  >
                    {t("AUTH.USE_MFA")}
                  </Text>
                </Text>
              ) : (
                <Text mb="48px">
                  {t("AUTH.CANT_USE_MFA")}{" "}
                  <Text
                    as="span"
                    color="#3179FF"
                    cursor="pointer"
                    onClick={() => setRenderRecoveryCode(true)}
                  >
                    {t("AUTH.USE_RECOVERY_CODE")}
                  </Text>
                </Text>
              )}
              <Footer />
            </Box>
            {!constants.IS_EASTER_CAMPAIGN && (
              <Box>
                <Image
                  src={giftImage}
                  display={{ base: "none", lg: "initial" }}
                  alt=""
                />
              </Box>
            )}
          </Flex>
        </Container>
      </Flex>
    </Flex>
  );
}

export default Mfa;
