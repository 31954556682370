import axios, { AxiosError } from "axios";

import constants from "./constants";

const INSTANCE = axios.create({
  baseURL: constants.API_URL,
  headers: {
    "accept-version": constants.ACCEPT_VERSION.ONE,
    platform: constants.PLATFORM,
  },
});
INSTANCE.interceptors.request.use(
  function (config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: `JWT ${localStorage.getItem("token")}`,
        xsrf: localStorage.getItem("xsrf"),
      },
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);
INSTANCE.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: AxiosError) {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("xsrf");
      localStorage.removeItem("isAuthorized");
      // TODO: Refactor this to use zustand vanilla store
      window.open("/", "_self");
    }
    return Promise.reject(error);
  }
);

export default INSTANCE;
