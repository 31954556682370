import React from "react";
import { Box, Container, Heading, Grid } from "@chakra-ui/react";
import { isNil, equals, map, isEmpty } from "ramda";
import Slider, { CustomArrowProps } from "react-slick";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import Tier from "./Tier";
import Gift from "./Gift";

import { encodeURIComponentAdvanced } from "../../helpers/helper-functions";
import constants from "../../constants";
import { TIERS } from "./constants/tiers-contants";
import api from "../../api";
import utils from "../../utils";

function PrevArrow(props: CustomArrowProps) {
  return <ChevronLeft {...props} color="#fff" />;
}

function NextArrow(props: CustomArrowProps) {
  return <ChevronRight {...props} color="#fff" />;
}

const SETTINGS = {
  infinite: false,
  swipeToSlide: true,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function Tiers() {
  const { t } = useTranslation();
  const [selectedTier, setSelectedTier] = React.useState(0);
  const loyaltyGroupsQuery = useQuery(constants.QUERY_NAMES.loyaltyGroups, () =>
    api.queries.loyaltyGroups()
  );
  const tierList = loyaltyGroupsQuery.data?.data.data || [];
  const name = tierList[selectedTier]?.group_points || 0;

  return (
    <Box color="#fff" backgroundColor="#171717" paddingY="80px">
      <Container maxW="container.xl">
        <Heading textAlign="center" mb="48px">
          {t("GROUPS.WHAT_GIFTS_CAN_I_CLAIM")}
        </Heading>
        <Box paddingX={{ base: "1rem", lg: 0 }}>
          {isEmpty(tierList) ? null : (
            <Slider {...SETTINGS}>
              {tierList.map((item, index) => (
                <Tier
                  key={item.group_id}
                  id={item.group_id}
                  Icon={TIERS[index].icon}
                  amount={item.group_points}
                  isSelected={equals(index, selectedTier)}
                  isCurrentLevel={false}
                  onClick={() => setSelectedTier(index)}
                />
              ))}
            </Slider>
          )}
        </Box>
        <Heading mb="24px">
          {t("GROUPS.GIFTS")} - {utils.formatter.numberFormat(name)}{" "}
          {t("POINTS")}
        </Heading>
        <Grid
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
          gridGap="24px"
        >
          {map(
            (item) => (
              <Gift
                key={item.product_id}
                id={item.product_id}
                claimedCount={item.total_redeemed}
                backgroundImage={`${constants.ASSETS_BUCKET_URL}/${encodeURIComponentAdvanced(item.image_url || '')}`}
                title={item.product_name}
                isClaimed={!isNil(item.buy_date)}
                isMissingPoints={false}
              />
            ),
            tierList[selectedTier]?.available_products || []
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default Tiers;
