type props = {
  size?: number;
};

function DiamondMedal(props: props) {
  const { size = 40, ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 86 112"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint0_linear_578_1606)"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint1_radial_578_1606)"
        fillOpacity="0.6"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint2_radial_578_1606)"
        fillOpacity="0.2"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint3_radial_578_1606)"
        fillOpacity="0.3"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint4_linear_578_1606)"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint5_radial_578_1606)"
        fillOpacity="0.6"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint6_radial_578_1606)"
        fillOpacity="0.2"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint7_radial_578_1606)"
        fillOpacity="0.3"
      />
      <path
        d="M52.6749 42.0745L46.6549 58.7992L53.75 58.3532L35.9051 84.6667L36.6576 63.3706L32.25 63.4821L34.1851 44.7505L52.6749 42.0745ZM38.7 27.3334C44.935 29.7864 43.86 36.6993 43.86 36.6993L52.46 40.7132L34.185 43.1662L41.3875 36.8108C42.0325 30.5669 37.9475 29.6749 37.9475 29.6749L38.7 27.3334Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_578_1606"
          x1="43"
          y1="-3"
          x2="43"
          y2="115.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9900" />
          <stop offset="1" stopColor="#E79B2C" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_578_1606"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(66 41) rotate(80.7996) scale(71.9253 32.3216)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_578_1606"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-8.90205e-06 97.5) rotate(47.5448) scale(63.7024 47.6383)"
        >
          <stop offset="0.00716212" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_578_1606"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(77.5 88) rotate(-128.755) scale(94.251 70.4834)"
        >
          <stop stopColor="#E4AA42" />
          <stop offset="1" stopColor="#F8B200" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_578_1606"
          x1="43"
          y1="-3"
          x2="43"
          y2="115.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#47E07B" />
          <stop offset="1" stopColor="#0EBB50" />
        </linearGradient>
        <radialGradient
          id="paint5_radial_578_1606"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(66 41) rotate(80.7996) scale(71.9253 32.3216)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_578_1606"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-8.90205e-06 97.5) rotate(47.5448) scale(63.7024 47.6383)"
        >
          <stop offset="0.00716212" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_578_1606"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(77.5 88) rotate(-128.755) scale(94.251 70.4834)"
        >
          <stop stopColor="#74CD85" />
          <stop offset="1" stopColor="#67EF80" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default DiamondMedal;
