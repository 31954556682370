import {
  Flex,
  Avatar,
  Text,
  Button,
  Grid,
  Box,
  Progress as ChakraProgress,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { calculatePercentage } from "../../helpers/helper-functions";
import constants from "../../constants";
import { TIERS } from "./constants/tiers-contants";
import api from "../../api";

type props = {
  currentBalance: number;
};

function Progress(props: props) {
  const { currentBalance } = props;
  const { t } = useTranslation();
  const userInfoQuery = useQuery(constants.QUERY_NAMES.userInfo, () =>
    api.queries.userInfo()
  );
  const autologinUrlQuery = useQuery(
    constants.QUERY_NAMES.autologinUrl,
    () =>
      api.queries.autologinUrl({
        dest: constants.AUTOLOGIN_URL_DESTINATIONS.WEB_TRADER,
        page: constants.AUTOLOGIN_PAGE_DESTINATIONS.FUNDING,
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const userId = userInfoQuery?.data?.data.data.user_id || "";
  const avatarUrl = `${
    constants.PHOTO_PROFILE_URL
  }${userId}.jpg?${new Date().getTime()}`;

  async function handleOnDeposit() {
    const resp = await autologinUrlQuery.refetch();
    const url = resp.data?.data.data.url;
    if (url) {
      window.open(url, "_blank")?.focus();
    }
  }

  return (
    <Grid
      padding="32px"
      alignItems="center"
      backgroundColor="#0A0A0A"
      borderRadius={["3xl", "3xl", "full"]}
      gridGap="32px"
      gridTemplateAreas={[
        `'avatar deposit' 'progress progress' 'cta cta'`,
        `'avatar deposit' 'progress progress' 'cta cta'`,
        `'avatar deposit progress cta'`,
      ]}
      gridTemplateColumns={["1fr 1fr", "1fr 1fr", "auto auto 1fr auto"]}
    >
      <Avatar gridArea="avatar" src={avatarUrl} />
      <Flex gridArea="deposit" flexDir="column">
        <Text color="#fff" fontWeight="bold">
          {t("PROGRESS.YOUR_PROGRESS")}
        </Text>
        <Text color="#747474">
          <Text as="span" fontWeight="bold">
            {currentBalance}
          </Text>{" "}
          {t("POINTS")}
        </Text>
      </Flex>

      <Box position="relative">
        {constants.IS_EASTER_CAMPAIGN &&
          TIERS.map(({ icon: Icon, amount, id }) => (
            <Flex
              key={id}
              position="absolute"
              top="-12px"
              zIndex={1}
              left={`calc(${calculatePercentage(
                amount,
                constants.MAX_PROGRESS
              )}% - 35px)`}
            >
              <Icon />
            </Flex>
          ))}
        <ChakraProgress
          gridArea="progress"
          value={currentBalance}
          max={constants.MAX_PROGRESS}
          size="lg"
          borderRadius="xl"
          colorScheme="pink"
        />
      </Box>
      <Button
        gridArea="cta"
        colorScheme="blue"
        borderRadius="3xl"
        onClick={handleOnDeposit}
        isLoading={autologinUrlQuery.isFetching}
      >
        {t("PROGRESS.DEPOSIT_FUNDS")}
      </Button>
    </Grid>
  );
}

export default Progress;
