import axios from "../axios";

import {
  LoginInput,
  LoginOutput,
  MfaLoginInput,
  MfaLoginOutput,
  BuyLoyaltyProductInput,
  BuyLoyaltyProductOutput,
} from "./apiTypes";

function login(data: LoginInput): Promise<LoginOutput> {
  return axios.post("/user/login", data);
}

function mfaLogin(data: MfaLoginInput): Promise<MfaLoginOutput> {
  return axios.post("/mfa/login", data);
}

function buyLoyaltyProduct(
  data: BuyLoyaltyProductInput
): Promise<BuyLoyaltyProductOutput> {
  return axios.post("/store/product/buy", data);
}

const EXPORTS = {
  login,
  mfaLogin,
  buyLoyaltyProduct,
};

export default EXPORTS;
