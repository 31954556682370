import { Box, Container, Flex } from "@chakra-ui/layout";
import { useMediaQuery } from "@react-hookz/web";

import Logo from "../common/Logo";
import LanguagePicker from "../common/LanguagePicker";

function Header() {
  const isMediumDevice = useMediaQuery("only screen and (min-width: 30em");
  return (
    <Box as="header" paddingY="42px">
      <Container maxW="container.xl">
        <Flex justifyContent="space-between" alignItems="center">
          <Logo size={isMediumDevice ? 48 : 24} />
          <LanguagePicker />
        </Flex>
      </Container>
    </Box>
  );
}

export default Header;
