import { Box } from "@chakra-ui/layout";

import TrustBox from "../common/TrustBox";

function Footer() {
  return (
    <Box as="footer">
      <TrustBox />
    </Box>
  );
}

export default Footer;
