type props = {
  size?: number;
  color?: string;
};

function Logo(props: props) {
  const { size = 48, color = "currentColor", ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...rest}
    >
      <path
        d="M13.907 0h20.186c4.836 0 6.59.522 8.357 1.503 1.768.98 3.156 2.419 4.101 4.252C47.496 7.59 48 9.407 48 14.422v19.156c0 5.015-.504 6.833-1.449 8.667-.945 1.833-2.333 3.272-4.1 4.252-1.769.98-3.522 1.503-8.358 1.503H13.907c-4.836 0-6.59-.522-8.357-1.503-1.768-.98-3.156-2.419-4.101-4.252C.503 40.41 0 38.593 0 33.578V14.422c0-5.015.503-6.833 1.449-8.667.945-1.833 2.333-3.272 4.1-4.252C7.319.523 9.072 0 13.908 0z"
        fill={color}
      />
      <path
        d="M30.132 25.15l-3.808.246 3.231-9.195-9.922 1.471-1.038 10.298 2.365-.061-.404 11.708 9.576-14.466zM19.633 16.801l9.807-1.348-4.615-2.207s.576-3.8-2.77-5.15l-.403 1.288s2.192.49 1.846 3.923l-3.865 3.494z"
        fill="#D53333"
      />
    </svg>
  );
}

export default Logo;
