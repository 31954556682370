import React from "react";
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Flex, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import { Checkbox } from "@chakra-ui/react";
import { useTranslation, Trans } from "react-i18next";
import { useQuery } from "react-query";
import { Info } from "react-feather";

import constants from "../../constants";
import api from "../../api";

type props = {
  isOpen: boolean;
  imageUrl: string;
  onClose: () => void;
  onClaim: () => void;
};

function GiftModal(props: props) {
  const { isOpen, imageUrl, onClose, onClaim } = props;
  const { t } = useTranslation();

  const [isTermsAccepted, setIsTermsAccepted] = React.useState<boolean>(true);

  const userInfoQuery = useQuery(constants.QUERY_NAMES.userInfo, () =>
    api.queries.userInfo()
  );

  const countriesQuery = useQuery(constants.QUERY_NAMES.allCountries, () =>
    api.queries.allCountries()
  );

  const printUserInfo = () => {
    const {
      first_name,
      last_name,
      city,
      address1,
      address2,
      phone_number,
      zip,
      country: countryCode,
    } = userInfoQuery?.data?.data?.data || {};

    const country = (countriesQuery?.data?.data?.data || []).find(
      (c) => c.code === countryCode
    );

    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        mb={5}
        mt={5}
        justifyContent="center"
        backgroundColor="#F9F9F9"
        p={3}
      >
        <Text fontSize={14}>
          {first_name} {last_name}
        </Text>
        <Text fontSize={14}>{phone_number}</Text>
        <Text fontSize={14}>{address1 ? address1 : address2}</Text>
        <Text fontSize={14}>{city}</Text>
        <Text fontSize={14}>
          {zip ? `${zip}, ` : ""}
          {country?.name}
        </Text>
      </Flex>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text align="center"> {t("STORE_DETAILS.GIFT_MODAL_TITLE")} </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Image
          src={imageUrl}
          alt=""
          objectFit="cover"
          maxHeight="246px"
          borderTopRadius="md"
          mb="15px"
        />
        <ModalBody textAlign="center">
          <Text ml="6px">{t("STORE_DETAILS.GIFT_MODAL_TEXT")}</Text>
          {printUserInfo()}
          <Flex
            gridGap={3}
            alignItems="flex-start"
            mb={5}
            backgroundColor="#FF99001A"
            p={3}
          >
            <Info color="#FF9900" />
            <Text
              width="100%"
              textAlign="left"
              color="#FF9900"
              fontSize={14}
              fontWeight={400}
            >
              {t("STORE_DETAILS.GIFT_MODAL_CONTACT_SUPPORT")}
            </Text>
          </Flex>
          <Checkbox
            isChecked={isTermsAccepted}
            onChange={(e) => setIsTermsAccepted(e.target.checked)}
            size="lg"
            mb={7}
            alignItems="flex-start"
          >
            <Text align="left" fontSize={14} color="#7F93BC">
              <Trans
                i18nKey="STORE_DETAILS.GIFT_MODAL_ACCEPT_TERMS_AND_CONDITIONS"
                t={t}
                components={{
                  terms: (
                    <a
                      style={{ color: "#3179FF" }}
                      href={constants.TERMS_AND_CONDITIONS}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("STORE.TERMS_AND_CONDITIONS")}
                    </a>
                  ),
                }}
              />
            </Text>
          </Checkbox>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" flexDir="column">
            <Button
              disabled={!isTermsAccepted}
              colorScheme="blue"
              isFullWidth
              onClick={onClaim}
            >
              {t("STORE_DETAILS.GIFT_MODAL_CONFIRM")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default GiftModal;
