import React from "react";
import {
  Flex,
  Box,
  Container,
  Text,
  Grid,
  Heading,
  Divider,
  Button,
  Alert,
  AlertIcon,
  AlertDescription,
  useDisclosure,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ChevronLeft, Info } from "react-feather";
import { Link, useParams } from "react-router-dom";
import { useDocumentTitle } from "@react-hookz/web";
import { useMutation, useQuery } from "react-query";
import { isNil, flatten, uniq, isEmpty, and, or } from "ramda";
import { useTranslation, Trans } from "react-i18next";

import Header from "../store/Header";
import ItemProperties from "./ItemProperties";
import ClaimedModal from "./ClaimedModal";
import ItemGallery from "./ItemGallery";
import GiftModal from "../store-item/GiftModal";

import { encodeURIComponentAdvanced } from "../../helpers/helper-functions";
import constants from "../../constants";
import api from "../../api";
import utils from "../../utils";

enum Attributes {
  size = "size",
  color = "color",
}

function StoreItem() {
  const { id }: { id?: string | undefined } = useParams();
  const { t } = useTranslation();
  const [size, setSize] = React.useState<number | null>(null);
  const [color, setColor] = React.useState<number | null>(null);
  const [productImage, setProductImage] = React.useState<string>("");

  const { data, isLoading, remove } = useQuery(
    constants.QUERY_NAMES.loyaltyProduct,
    () => api.queries.loyaltyProduct(Number(id)),
    { enabled: !isNil(id) }
  );
  const { data: balanceData, refetch: balanceRefetch } = useQuery(
    constants.QUERY_NAMES.loyaltyBalance,
    () => api.queries.loyaltyBalance(),
    {
      enabled: false,
    }
  );
  const autologinUrlQuery = useQuery(
    constants.QUERY_NAMES.autologinUrl,
    () =>
      api.queries.autologinUrl({
        dest: constants.AUTOLOGIN_URL_DESTINATIONS.WEB_TRADER,
        page: constants.AUTOLOGIN_PAGE_DESTINATIONS.FUNDING,
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const { data: excludedDeliveryCountries, isSuccess } = useQuery(
    constants.QUERY_NAMES.excludedDeliveryCountries,
    () => api.queries.excludedDeliveryCountries(Number(id))
  );

  const productHasDelivery = isSuccess
    ? isEmpty(excludedDeliveryCountries?.data.data)
    : true;
  const totalRedeemed = balanceData?.data.data.total_redeemed || 0;
  const currenctBalance = balanceData?.data.data.current_balance || 0;
  const product = data?.data.data[0] || {
    buy_date: null,
    images: [],
    product_price: 0,
    product_name: "",
    description: "",
    attributes: [],
  };
  const imageList = uniq(
    flatten(data?.data.data.map((item) => item.images) || [])
  );
  const images = imageList.map((item) => {
    return {
      ...item,
      image_url: `${constants.ASSETS_BUCKET_URL}/${encodeURIComponentAdvanced(item.image_url || '')}`,
    };
  });
  const attributes = flatten(
    data?.data.data.map((item) => item.attributes || {}) || []
  );
  const sizes = uniq(
    attributes.filter((item) => item.attribute_type === Attributes.size) || []
  );
  const hasSizes = !isEmpty(sizes);
  const colors = uniq(
    attributes.filter((item) => item.attribute_type === Attributes.color) || []
  );
  const hasColors = !isEmpty(colors);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buyProductMutation = useMutation(api.mutations.buyLoyaltyProduct, {
    onSuccess,
    onError,
  });
  const toast = useToast();

  const giftModal = useDisclosure();

  function onSuccess() {
    onOpen();
  }

  function onError() {
    toast({
      title: t("ERROR"),
      description: t("SOMETHING_WENT_WRONG"),
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  }

  function handleOnClick() {
    const skuId = data?.data.data.find((item) => {
      const attributes = item.attributes || [];
      const sizeId = attributes.find(
        (item) => item.attribute_type === Attributes.size
      )?.attribute_id;
      const colorId = attributes.find(
        (item) => item.attribute_type === Attributes.color
      )?.attribute_id;

      if (hasSizes && sizeId === size && hasColors && colorId === color) {
        return item;
      }
      if (hasSizes && sizeId === size && !hasColors) {
        return item;
      }
      if (hasColors && colorId === color && !hasSizes) {
        return item;
      }
      return item;
    })?.product_sku_id;
    if (skuId) {
      buyProductMutation.mutate({ product_sku_id: skuId });
    }
    giftModal.onClose();
  }

  function getIsDisabled() {
    if (!isNil(product.buy_date)) {
      return true;
    }
    if (hasSizes && isNil(size)) {
      return true;
    }
    if (hasColors && isNil(color)) {
      return true;
    }
    return false;
  }

  function getButtonText() {
    if (!isNil(product.buy_date)) {
      return t("STORE.ALREADY_CLAIMED");
    }
    return t("STORE_DETAILS.CLAIM_GIFT");
  }

  async function handleOnDeposit() {
    const resp = await autologinUrlQuery.refetch();
    const url = resp.data?.data.data.url;
    if (url) {
      window.open(url, "_blank")?.focus();
    }
  }

  function getAlertText() {
    if (totalRedeemed >= 3) {
      return t("STORE_DETAILS.MAXIMUM_NUMBER_OF_REDEEMABLE_GIFTS_REACHED");
    }
    if (product.product_price > currenctBalance) {
      const amountNeeded = product.product_price - currenctBalance;
      return (
        <React.Fragment>
          <Text as="span">
            {t(
              "STORE_DETAILS.YOU_NEED_TO_DEPOSIT_MORE_TO_BE_ABLE_TO_CLAIM_THIS_GIFTS",
              { amount: utils.formatter.numberFormat(amountNeeded) }
            )}
          </Text>{" "}
          <Text
            as="span"
            cursor="pointer"
            fontWeight="bold"
            textDecoration="underline"
            onClick={handleOnDeposit}
          >
            {t("STORE_DETAILS.DEPOSIT_FUNDS")}
          </Text>
        </React.Fragment>
      );
    }
  }

  React.useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  React.useEffect(() => {
    if (isNil(balanceData)) {
      balanceRefetch();
    }
  }, [balanceData, balanceRefetch]);

  React.useEffect(() => {
    if (and(colors.length, colors.length === 1)) {
      setColor(colors[0].attribute_id);
    }
  }, [colors, colors.length]);

  React.useEffect(() => {
    if (and(sizes.length, sizes.length === 1)) {
      setSize(sizes[0].attribute_id);
    }
  }, [sizes, sizes.length]);

  useDocumentTitle(`${constants.DOCUMENT_TITLE} | ${product?.product_name}`, {
    restoreOnUnmount: true,
  });

  const isDisabled = getIsDisabled();
  const showWarning = or(
    totalRedeemed >= 3,
    product.product_price > currenctBalance
  );

  return (
    <React.Fragment>
      <Flex flexDir="column">
        <Header isDark />
        <Container maxW="container.lg" paddingY="48px">
          <Link to="/">
            <Flex display="inline-flex" alignItems="center" mb="36px">
              <ChevronLeft />
              <Text marginLeft="4px">{t("BACK")}</Text>
            </Flex>
          </Link>
          <Grid
            gridTemplateColumns={["1fr", "1fr 1fr"]}
            gridTemplateAreas={[
              `'name' 'gallery' 'description' 'attributes' 'divider' 'cta'`,
              `'gallery name' 'gallery description' 'gallery attributes' 'gallery divider' 'gallery cta'`,
            ]}
            rowGap={["24px", "0"]}
            columnGap={["0", "32px"]}
          >
            <Box gridArea="name">
              <Heading fontWeight="bold" mb="4px">
                {product.product_name}
              </Heading>
              <Text color="#686868">
                {t("STORE_DETAILS.WORTH")}: {product.product_price}{" "}
                {t("POINTS")}
              </Text>
            </Box>
            <ItemGallery
              gridArea="gallery"
              setProductImage={setProductImage}
              productImage={productImage}
              images={images}
            />
            <Text gridArea="description" color="#686868">
              {product.description}
            </Text>
            <Flex gridArea="attributes" flexDir="column">
              {hasSizes ? (
                <Box mb="24px">
                  <Text mb="4px">{t("STORE_DETAILS.SIZE")}</Text>
                  <ItemProperties
                    property={sizes}
                    active={size}
                    onClick={(value: number) => setSize(value)}
                  />
                </Box>
              ) : null}
              {hasColors ? (
                <Box>
                  <Text mb="4px">{t("STORE_DETAILS.COLOR")}</Text>
                  <ItemProperties
                    property={colors}
                    active={color}
                    onClick={(value: number) => setColor(value)}
                  />
                </Box>
              ) : null}
            </Flex>
            <Divider gridArea="divider" />

            {showWarning ? (
              <Alert
                gridArea="cta"
                status="warning"
                borderRadius="lg"
                height="64px"
              >
                <AlertIcon />
                <AlertDescription>{getAlertText()}</AlertDescription>
              </Alert>
            ) : (
              <Box gridArea="cta">
                {!productHasDelivery && (
                  <Flex justify="flex-start" align="center" mb="20px">
                    <Text
                      color="#7F93BC"
                      fontSize={14}
                      mr="5px"
                      fontWeight={400}
                    >
                      {t("STORE_DETAILS.PRODUCT_UNAVAILABLE")}
                    </Text>
                    <Popover trigger="hover" placement="right">
                      <PopoverTrigger>
                        <Info color="#7F93BC" />
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>
                          {t("STORE_DETAILS.PRODUCT_UNAVAILABLE_INFO")}
                        </PopoverHeader>
                        <PopoverBody>
                          <Trans
                            i18nKey="STORE_DETAILS.PRODUCT_UNAVAILABLE_FOR_SOME_COUNTRIES"
                            t={t}
                            components={{
                              countries: (
                                <UnorderedList>
                                  {excludedDeliveryCountries?.data?.data.map(
                                    ({ name }) => (
                                      <ListItem>{name}</ListItem>
                                    )
                                  )}
                                </UnorderedList>
                              ),
                            }}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Flex>
                )}

                <Button
                  as={motion.button}
                  whileHover={{
                    scale: 1.1,
                  }}
                  borderRadius="lg"
                  colorScheme="blue"
                  isFullWidth
                  marginTop="auto"
                  height="48px"
                  isLoading={isLoading || buyProductMutation.isLoading}
                  isDisabled={isDisabled}
                  onClick={() => giftModal.onOpen()}
                >
                  {getButtonText()}
                </Button>
              </Box>
            )}
          </Grid>
        </Container>
      </Flex>
      <ClaimedModal
        isOpen={isOpen}
        onClose={onClose}
        imageUrl={images[0]?.image_url}
        name={product.product_name}
      />
      <GiftModal
        isOpen={giftModal.isOpen}
        imageUrl={productImage}
        onClose={giftModal.onClose}
        onClaim={handleOnClick}
      />
    </React.Fragment>
  );
}

export default StoreItem;
