import React from "react";
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Avatar,
  Text,
  Flex,
} from "@chakra-ui/react";
import { ChevronDown, LogOut } from "react-feather";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import { isNil } from "ramda";
import { useIntercom } from "react-use-intercom";

import state from "../../state";
import constants from "../../constants";
import api from "../../api";

function UserMenu() {
  const { t } = useTranslation();
  const { shutdown } = useIntercom();
  const useAuth = state.useAuth();
  const history = useHistory();
  const userInfoQuery = useQuery(constants.QUERY_NAMES.userInfo, () =>
    api.queries.userInfo()
  );
  const username = userInfoQuery?.data?.data.data.user_name || "";
  const userId = userInfoQuery?.data?.data.data.user_id || "";
  const avatarUrl = `${
    constants.PHOTO_PROFILE_URL
  }${userId}.jpg?${new Date().getTime()}`;

  React.useEffect(() => {
    if (isNil(userInfoQuery.data?.data.data.user_id)) {
      userInfoQuery.refetch();
    }
  }, [userInfoQuery]);

  function handleOnLogOut() {
    shutdown();
    useAuth.logOut();
    history.push("/");
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        backgroundColor="transparent"
        color="#fff"
        _hover={{
          backgroundColor: "transparent",
        }}
        _active={{
          backgroundColor: "transparent",
        }}
        rightIcon={<ChevronDown size={16} />}
      >
        <Flex alignItems="center">
          <Avatar size="sm" mr="8px" src={avatarUrl} />
          <Text as="span">{username}</Text>
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem icon={<LogOut size={16} />} onClick={handleOnLogOut}>
          {t("AUTH.LOG_OUT")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default UserMenu;
