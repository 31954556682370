import React from "react";
import {
  InputGroup,
  Input,
  InputRightElement,
  InputProps,
} from "@chakra-ui/input";
import { Eye, EyeOff } from "react-feather";

type props = {} & InputProps;

function PasswordInput(
  props: props,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { height, ...rest } = props;
  const [show, setShow] = React.useState(false);

  function handleOnClick() {
    setShow(!show);
  }

  return (
    <InputGroup>
      <Input
        ref={ref}
        {...rest}
        type={show ? "text" : "password"}
        height={height}
      />
      <InputRightElement height={height} width={height} onClick={handleOnClick}>
        {show ? <EyeOff size={16} /> : <Eye size={16} />}
      </InputRightElement>
    </InputGroup>
  );
}

export default React.forwardRef(PasswordInput);
