type props = {
  size?: number;
};

function BronzeMedal(props: props) {
  const { size = 40, ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 86 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint0_linear_35_416)"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint1_radial_35_416)"
        fillOpacity="0.5"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint2_radial_35_416)"
        fillOpacity="0.2"
      />
      <path
        d="M86 67C86 92.9868 63.9868 112 43 112C22.0132 112 0 92.9868 0 67C0 41.0132 17.0132 0 43 0C68.9868 0 86 41.0132 86 67Z"
        fill="url(#paint3_radial_35_416)"
        fillOpacity="0.3"
      />
      <path
        d="M52.6749 42.0744L46.6549 58.7992L53.75 58.3531L35.9051 84.6666L36.6576 63.3705L32.25 63.482L34.1851 44.7504L52.6749 42.0744ZM38.7 27.3333C44.935 29.7864 43.86 36.6992 43.86 36.6992L52.46 40.7131L34.185 43.1661L41.3875 36.8107C42.0325 30.5668 37.9475 29.6749 37.9475 29.6749L38.7 27.3333Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_35_416"
          x1="43"
          y1="-3"
          x2="43"
          y2="115.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F74855" />
          <stop offset="1" stopColor="#9B2128" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_35_416"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(68 40) rotate(82.3039) scale(74.6726 33.5562)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FF8D8D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_35_416"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-8.90205e-06 97.5) rotate(47.5448) scale(63.7024 47.6383)"
        >
          <stop offset="0.00716212" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_35_416"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(77.5 88) rotate(-128.755) scale(94.251 70.4834)"
        >
          <stop stopColor="#FF7B7B" />
          <stop offset="1" stopColor="#FF5151" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default BronzeMedal;
