import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Flex, Text } from "@chakra-ui/layout";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import { CheckCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type props = {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string;
  name: string;
};

function ClaimedModal(props: props) {
  const { isOpen, onClose, imageUrl, name } = props;
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Image
          src={imageUrl}
          alt=""
          objectFit="cover"
          maxHeight="246px"
          borderTopRadius="md"
          mb="32px"
        />
        <ModalBody textAlign="center">
          <Flex alignItems="center" mb="12px" justifyContent="center">
            <CheckCircle color="#3DC752" />
            <Text ml="6px" fontWeight="bold">
              {t("STORE_DETAILS.GIFT_CLAIMED")}
            </Text>
          </Flex>
          <Text color="#7F93BC">
            {t(
              "STORE_DETAILS.YOU_HAVE_SUCCESSFULLY_CLAIMED_ITEM_IT_IS_ON_THE_WAY_TO_YOU",
              { name }
            )}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" flexDir="column">
            <Link to="/">
              <Button variant="outline" isFullWidth mb="12px">
                {t("STORE_DETAILS.GO_TO_MY_GIFTS")}
              </Button>
            </Link>
            <Button colorScheme="blue" isFullWidth onClick={onClose}>
              {t("DONE")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ClaimedModal;
