import LevelSvg from "./assets/4.svg";

type props = {
  size?: number;
};

function LevelFour(props: props) {
  const { size = 40, ...rest } = props;
  return <img src={LevelSvg} width={size} alt="Level 6" {...rest}/>;
}

export default LevelFour;
