type props = {
  size?: number;
};

function PlatinumMedal(props: props) {
  const { size = 40, ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M39.392 31.655l4.013-3.107a.479.479 0 00-.066-.81c-1.593-.814-5.873-2.823-10.767-3.648a.492.492 0 00-.572.49v10.964c0 .247.18.457.424.498 4.036.678 7.836 2.211 9.92 3.158.465.21.958-.335.691-.77l-3.763-6.118a.5.5 0 01.12-.657z"
        fill="#086FE9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.36 32.171l3.657-2.832c.833-.645.78-1.98-.223-2.492-1.63-.832-6.015-2.894-11.056-3.743A1.492 1.492 0 0031 24.58v10.965c0 .745.543 1.364 1.258 1.484 3.913.658 7.622 2.151 9.673 3.083.704.32 1.396.038 1.776-.384.382-.424.592-1.15.18-1.82l-3.528-5.736zm3.045-3.623l-4.013 3.107a.5.5 0 00-.12.657l3.763 6.119c.267.434-.226.98-.69.769-2.085-.947-5.885-2.48-9.92-3.158a.507.507 0 01-.425-.498V24.58c0-.305.271-.54.572-.489 4.894.825 9.174 2.834 10.767 3.647.318.163.349.593.066.811z"
        fill="#023E84"
      />
      <path
        d="M40.5 29.701c0-3.57-.572-5.36-.842-6.008a1.03 1.03 0 00-.403-.477c-.744-.47-3.056-1.574-6.917-.33a.491.491 0 00-.338.469v11.653c0 .33.315.569.637.498a12.28 12.28 0 016.043.196c.488.145 1.018-.043 1.17-.528.285-.905.65-2.622.65-5.473z"
        fill="#0355B6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5 29.7c0-3.675-.589-5.601-.919-6.392a2.029 2.029 0 00-.793-.939c-.957-.603-3.575-1.783-7.757-.435A1.492 1.492 0 0031 23.354v11.654c0 1.008.944 1.673 1.85 1.475a11.282 11.282 0 015.545.177c.842.25 2.045-.026 2.41-1.187.32-1.017.695-2.837.695-5.772zm-1.842-6.007c.27.647.842 2.438.842 6.008 0 2.85-.365 4.567-.65 5.472-.152.486-.682.673-1.17.529a12.279 12.279 0 00-6.043-.196.521.521 0 01-.637-.498V23.355c0-.213.136-.404.338-.469 3.861-1.244 6.173-.14 6.917.33.182.114.32.279.403.477z"
        fill="#023E84"
      />
      <path
        d="M8.608 31.655l-4.013-3.107a.479.479 0 01.066-.81c1.593-.814 5.873-2.823 10.767-3.648a.492.492 0 01.572.49v10.964c0 .247-.18.457-.424.498-4.037.678-7.836 2.211-9.92 3.158-.465.21-.958-.335-.691-.77l3.763-6.118a.5.5 0 00-.12-.657z"
        fill="#086FE9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64 32.171l-3.657-2.832c-.833-.645-.78-1.98.223-2.492 1.63-.832 6.016-2.894 11.056-3.743A1.492 1.492 0 0117 24.58v10.965c0 .745-.543 1.364-1.258 1.484-3.913.658-7.622 2.151-9.673 3.083-.704.32-1.396.038-1.776-.384-.382-.424-.592-1.15-.18-1.82l3.528-5.736zm-3.045-3.623l4.013 3.107a.5.5 0 01.12.657l-3.763 6.119c-.267.434.226.98.69.769 2.085-.947 5.885-2.48 9.92-3.158a.507.507 0 00.425-.498V24.58a.492.492 0 00-.572-.489c-4.894.825-9.174 2.834-10.767 3.647a.479.479 0 00-.066.811z"
        fill="#023E84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.56 35.13a.5.5 0 01-.44.554c-1.705.198-3.56.68-5.142 1.205-1.59.527-2.861 1.084-3.406 1.414a.5.5 0 01-.518-.856c.642-.388 1.996-.973 3.609-1.508 1.621-.537 3.547-1.04 5.342-1.248a.5.5 0 01.554.439z"
        fill="#3D96FF"
      />
      <path
        d="M7.5 29.701c0-3.57.572-5.36.842-6.008a1.03 1.03 0 01.403-.477c.744-.47 3.056-1.574 6.917-.33a.492.492 0 01.338.469v11.653c0 .33-.315.569-.637.498a12.28 12.28 0 00-6.043.196c-.488.145-1.018-.043-1.17-.528-.285-.905-.65-2.622-.65-5.473z"
        fill="#0355B6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 29.7c0-3.675.589-5.601.92-6.392a2.03 2.03 0 01.792-.939c.957-.603 3.575-1.783 7.757-.435A1.492 1.492 0 0117 23.354v11.654c0 1.008-.944 1.673-1.85 1.475a11.281 11.281 0 00-5.545.177c-.842.25-2.045-.026-2.41-1.187-.32-1.017-.695-2.837-.695-5.772zm1.842-6.007c-.27.647-.842 2.438-.842 6.008 0 2.85.365 4.567.65 5.472.152.486.682.673 1.17.529 1.146-.34 3.366-.78 6.043-.196a.521.521 0 00.637-.498V23.355a.492.492 0 00-.338-.469c-3.861-1.244-6.173-.14-6.917.33a1.03 1.03 0 00-.403.477z"
        fill="#023E84"
      />
      <path
        d="M8 29.5c0-2.515.413-4.513.646-5.439.092-.369.307-.689.622-.901 1.435-.967 6.198-3.66 14.732-3.66 8.484 0 13.242 2.662 14.706 3.643.331.221.554.56.646.948.232.98.648 3.064.648 5.409 0 1.824-.252 3.49-.473 4.602-.136.683-.938.983-1.549.647C35.871 33.591 31.086 31.5 24 31.5c-7.126 0-11.926 2.115-14.015 3.27-.6.33-1.382.047-1.52-.624A23.44 23.44 0 018 29.5z"
        fill="#086FE9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 29.5c0-2.62.43-4.703.676-5.683a2.522 2.522 0 011.033-1.486C10.287 21.268 15.242 18.5 24 18.5c8.707 0 13.655 2.735 15.263 3.812.557.373.916.935 1.062 1.549l-.973.23a1.561 1.561 0 00-.646-.948C37.242 22.163 32.484 19.5 24 19.5c-8.534 0-13.297 2.693-14.732 3.66-.315.212-.53.532-.623.901A22.922 22.922 0 008 29.5c0 1.942.246 3.576.466 4.646.137.671.92.955 1.52.623C12.073 33.615 16.873 31.5 24 31.5c7.086 0 11.871 2.091 13.978 3.25.61.335 1.413.035 1.549-.648.221-1.111.473-2.778.473-4.602 0-2.345-.416-4.43-.648-5.409l.973-.23c.241 1.019.675 3.187.675 5.639 0 1.91-.263 3.646-.492 4.797-.29 1.46-1.915 1.931-3.011 1.329C35.504 34.53 30.883 32.5 24 32.5c-6.923 0-11.557 2.054-13.53 3.145-1.077.594-2.686.156-2.984-1.299A24.437 24.437 0 017 29.5z"
        fill="#023E84"
      />
      <path
        d="M35.954 24a.579.579 0 01-.572.586.579.579 0 01-.571-.586c0-.323.256-.585.571-.585.316 0 .572.262.572.585zM33.047 22.06a.47.47 0 01-.463.476.47.47 0 01-.464-.475.47.47 0 01.464-.475.47.47 0 01.463.475zM33.542 24a.72.72 0 01-.71.728.72.72 0 01-.712-.728.72.72 0 01.711-.729.72.72 0 01.71.729zM35.472 26.267a.72.72 0 01-.711.728.72.72 0 01-.71-.729.72.72 0 01.71-.728.72.72 0 01.71.729zM36.944 25.902a.36.36 0 01-.355.364.36.36 0 01-.355-.364.36.36 0 01.355-.364.36.36 0 01.355.364zM33.186 26.53a.36.36 0 01-.355.365.36.36 0 01-.355-.364.36.36 0 01.355-.364.36.36 0 01.355.364zM31.113 21.95a.36.36 0 01-.356.364.36.36 0 01-.355-.364.36.36 0 01.355-.364.36.36 0 01.356.364zM34.811 22.536a.36.36 0 01-.355.364.36.36 0 01-.356-.364.36.36 0 01.356-.365.36.36 0 01.355.365zM36.59 28.445a.36.36 0 01-.356.364.36.36 0 01-.355-.364.36.36 0 01.355-.364.36.36 0 01.355.364zM34.761 28.809a.36.36 0 01-.355.364.36.36 0 01-.355-.364.36.36 0 01.355-.364.36.36 0 01.355.364zM38.883 31.292a.36.36 0 01-.355.364.36.36 0 01-.356-.364.36.36 0 01.356-.364.36.36 0 01.355.364zM37.527 24.045a.36.36 0 01-.356.365.36.36 0 01-.355-.365.36.36 0 01.355-.364.36.36 0 01.356.364zM38.305 27.488a.586.586 0 01-.578.593.586.586 0 01-.579-.593c0-.328.26-.593.578-.593.32 0 .579.265.579.593zM38.883 24.945a.586.586 0 01-.578.593.586.586 0 01-.578-.593c0-.327.259-.593.578-.593.32 0 .578.266.578.593zM37.746 30.13a.586.586 0 01-.578.593.586.586 0 01-.579-.593c0-.327.26-.592.579-.592.32 0 .578.265.578.592zM39.059 29.173a.36.36 0 01-.356.365.36.36 0 01-.355-.365.36.36 0 01.355-.364.36.36 0 01.356.364z"
        fill="#055EC8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.791 23.966a.5.5 0 01.306.638c-.422 1.193-.578 2.9-.59 4.59-.01 1.317.067 2.59.16 3.54 5.18-2.46 17.052-5.423 28.249.321a.5.5 0 11-.457.89c-11.306-5.8-23.376-2.415-27.977-.002a.5.5 0 01-.728-.38c-.128-.999-.26-2.65-.248-4.376.013-1.714.169-3.56.647-4.916a.5.5 0 01.638-.305z"
        fill="#3D96FF"
      />
      <path
        d="M28.483 10.177c2.073-.305 3.874.089 5.063 1.277 1.188 1.189 1.582 2.99 1.278 5.063 1.68 1.25 2.676 2.802 2.676 4.483 0 1.68-.995 3.233-2.676 4.483.304 2.073-.09 3.874-1.278 5.063-1.189 1.189-2.99 1.582-5.063 1.278-1.25 1.68-2.802 2.676-4.483 2.676-1.68 0-3.233-.995-4.483-2.676-2.073.304-3.874-.09-5.063-1.278-1.189-1.189-1.582-2.99-1.278-5.063-1.68-1.25-2.676-2.802-2.676-4.483 0-1.68.995-3.233 2.676-4.483-.304-2.073.09-3.874 1.278-5.063 1.189-1.188 2.99-1.582 5.063-1.277C20.767 8.495 22.319 7.5 24 7.5c1.681 0 3.233.995 4.483 2.677z"
        fill="#5DAECF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.922 9.118c2.043-.185 3.97.268 5.331 1.629 1.361 1.361 1.814 3.288 1.63 5.331C37.457 17.392 38.5 19.075 38.5 21c0 1.925-1.042 3.607-2.618 4.922.185 2.043-.267 3.97-1.629 5.331-1.361 1.362-3.288 1.814-5.331 1.63C27.607 34.457 25.925 35.5 24 35.5c-1.925 0-3.607-1.042-4.922-2.618-2.043.185-3.97-.267-5.331-1.629-1.362-1.361-1.814-3.288-1.63-5.331C10.543 24.607 9.5 22.925 9.5 21c0-1.925 1.043-3.608 2.618-4.922-.185-2.043.268-3.97 1.629-5.331 1.361-1.361 3.288-1.814 5.331-1.63C20.392 7.543 22.075 6.5 24 6.5c1.925 0 3.608 1.043 4.922 2.618zm-.439 1.059C27.233 8.495 25.681 7.5 24 7.5c-1.681 0-3.233.995-4.483 2.677-2.073-.305-3.874.089-5.063 1.277-1.189 1.189-1.582 2.99-1.278 5.063-1.68 1.25-2.676 2.802-2.676 4.483 0 1.68.995 3.233 2.676 4.483-.304 2.073.09 3.874 1.278 5.063 1.189 1.189 2.99 1.582 5.063 1.278 1.25 1.68 2.802 2.676 4.483 2.676 1.68 0 3.233-.995 4.483-2.676 2.073.304 3.874-.09 5.063-1.278 1.188-1.189 1.582-2.99 1.278-5.063 1.68-1.25 2.676-2.802 2.676-4.483 0-1.68-.995-3.233-2.676-4.483.304-2.073-.09-3.874-1.278-5.063-1.189-1.188-2.99-1.582-5.063-1.277z"
        fill="#71C2E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5 20.5a.5.5 0 01.5.5c0 1.683-.949 3.185-2.445 4.372.219 1.897-.172 3.63-1.363 4.82-1.19 1.19-2.923 1.582-4.82 1.363C27.185 33.051 25.683 34 24 34a.5.5 0 110-1c1.343 0 2.647-.794 3.75-2.277a.5.5 0 01.474-.196c1.827.268 3.312-.092 4.261-1.042.95-.95 1.31-2.434 1.042-4.261a.5.5 0 01.196-.474C35.205 23.647 36 22.343 36 21a.5.5 0 01.5-.5z"
        fill="#438EAD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 9c-1.343 0-2.648.795-3.75 2.277a.5.5 0 01-.474.196c-1.828-.268-3.312.092-4.261 1.042-.95.95-1.31 2.434-1.042 4.261a.5.5 0 01-.196.474C12.795 18.353 12 19.657 12 21a.5.5 0 01-1 0c0-1.683.949-3.185 2.445-4.372-.219-1.897.172-3.63 1.363-4.82 1.19-1.19 2.923-1.582 4.82-1.363C20.815 8.95 22.316 8 24 8a.5.5 0 010 1z"
        fill="#7BD0F3"
      />
      <path
        d="M27.321 12.983c1.536-.226 2.87.066 3.75.946.88.88 1.172 2.214.946 3.75C33.264 18.605 34 19.755 34 21s-.737 2.395-1.983 3.32c.226 1.537-.065 2.87-.946 3.751-.88.88-2.215 1.172-3.75.946C26.395 30.263 25.245 31 24 31s-2.395-.737-3.32-1.983c-1.537.226-2.87-.065-3.751-.946-.88-.88-1.172-2.215-.946-3.75C14.736 23.394 14 22.245 14 21s.737-2.395 1.982-3.321c-.225-1.535.067-2.87.947-3.75.88-.88 2.215-1.172 3.75-.946C21.605 11.737 22.755 11 24 11s2.395.737 3.321 1.983z"
        fill="#90DCFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.929 28.071c-.88-.88-1.172-2.214-.946-3.75C14.736 23.395 14 22.245 14 21s.737-2.395 1.982-3.32c-.225-1.537.067-2.87.947-3.751.88-.88 2.215-1.172 3.75-.946C21.605 11.737 22.755 11 24 11s2.395.737 3.321 1.983c1.535-.226 2.87.066 3.75.946.88.88 1.172 2.215.946 3.75C33.263 18.606 34 19.755 34 21s-.737 2.395-1.983 3.32c.226 1.537-.066 2.87-.946 3.751-.88.88-2.215 1.172-3.75.946C26.394 30.263 25.245 31 24 31s-2.395-.737-3.32-1.982c-1.537.225-2.87-.066-3.751-.947zm3.314 2c-1.516.11-2.972-.244-4.021-1.293-1.049-1.048-1.403-2.505-1.293-4.02C13.78 23.762 13 22.482 13 21s.78-2.763 1.93-3.757c-.11-1.516.243-2.972 1.292-4.021s2.505-1.402 4.02-1.293C21.238 10.78 22.518 10 24 10s2.763.78 3.757 1.93c1.516-.11 2.972.243 4.021 1.292s1.402 2.505 1.293 4.02C34.22 18.238 35 19.518 35 21s-.78 2.763-1.93 3.757c.11 1.516-.243 2.973-1.292 4.021-1.049 1.05-2.505 1.403-4.02 1.293C26.762 31.22 25.482 32 24 32s-2.763-.78-3.757-1.929z"
        fill="#2399C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 20.5a.5.5 0 01.5.5c0 1.245-.689 2.345-1.753 3.209.142 1.363-.149 2.628-1.03 3.509-.88.88-2.145 1.17-3.508 1.03C26.345 29.81 25.245 30.5 24 30.5a.5.5 0 110-1c.907 0 1.81-.537 2.588-1.583a.5.5 0 01.473-.196c1.29.19 2.308-.07 2.95-.71.64-.642.9-1.66.71-2.95a.5.5 0 01.196-.473c1.046-.779 1.583-1.68 1.583-2.588a.5.5 0 01.5-.5z"
        fill="#78C7E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12.5c-.907 0-1.81.537-2.588 1.583a.5.5 0 01-.473.196c-1.29-.19-2.308.07-2.95.71-.64.642-.9 1.66-.71 2.95a.5.5 0 01-.196.473c-1.046.779-1.583 1.68-1.583 2.588a.5.5 0 01-1 0c0-1.245.689-2.345 1.753-3.209-.142-1.363.149-2.628 1.03-3.508.88-.881 2.145-1.171 3.508-1.03.864-1.064 1.963-1.753 3.209-1.753a.5.5 0 110 1z"
        fill="#DBF5FF"
      />
    </svg>
  );
}

export default PlatinumMedal;
