import cz from "./cz.json";
import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import hu from "./hu.json";
import id from "./id.json";
import it from "./it.json";
import pl from "./pl.json";
import pt from "./pt.json";
import th from "./th.json";
import vi from "./vi.json";
import zh from "./zh.json";

const resources = {
  cz: {
    translation: cz,
  },
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  hu: {
    translation: hu,
  },
  id: {
    translation: id,
  },
  it: {
    translation: it,
  },
  pl: {
    translation: pl,
  },
  pt: {
    translation: pt,
  },
  th: {
    translation: th,
  },
  vi: {
    translation: vi,
  },
  "zh-hans": {
    translation: zh,
  },
};

export default resources;
