import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClientProvider, QueryClient } from "react-query";
import { extendTheme } from "@chakra-ui/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { init } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { IntercomProvider } from "react-use-intercom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import constants from "./constants";

const queryClient = new QueryClient();
const customTheme = {
  styles: {
    global: {
      iframe: {
        width: "100%",
      },
    },
  },
};
const theme = extendTheme({ ...customTheme });

init({
  enabled: constants.IS_PROD_ENV,
  dsn: constants.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <React.StrictMode>
    <IntercomProvider appId={constants.INTERCOM_APP_ID}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <GoogleReCaptchaProvider reCaptchaKey={constants.RE_CAPTCHA_KEY}>
            <App />
          </GoogleReCaptchaProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </IntercomProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
