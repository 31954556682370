import { Flex, Text, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import utils from "../../utils";

type props = {
  id: number;
  Icon: React.ComponentType<any>;
  amount: number;
  isSelected: boolean;
  isCurrentLevel: boolean;
  onClick: (id: number) => void;
};

function Tier(props: props) {
  const { id, Icon, amount, isSelected, isCurrentLevel, onClick } = props;
  const { t } = useTranslation();
  const color = isSelected ? "#3179FF" : "#fff";
  const backgroundColor = isSelected ? "rgba(49, 121, 255, 0.1)" : "#232323";
  const borderColor = isSelected ? "#3179FF" : "#232323";
  return (
    <Flex
      as={motion.div}
      onClick={() => onClick(id)}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      position="relative"
      flexDir="column"
      alignItems="center"
      borderRadius="2xl"
      padding="32px 62px"
      cursor="pointer"
      borderWidth="2px"
      borderStyle="solid"
      marginY="32px"
      marginX="16px"
    >
      <Flex position="absolute" top="-20px">
        <Icon width={40} />
      </Flex>
      <Text mb="4" color={color} fontWeight="bold" fontSize="larger">
        ${utils.formatter.numberFormat(amount)}
      </Text>
      <Flex alignItems="center">
        <Text color={isSelected ? "#3179FF" : "#686868"}>
          {t("STORE.DEPOSITED")}
        </Text>
        {isCurrentLevel ? (
          <Box
            ml="4px"
            borderRadius="lg"
            padding="4px"
            backgroundColor={backgroundColor}
          >
            <Text textTransform="uppercase">{t("STORE.YOU")}</Text>
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );
}

export default Tier;
