type props = {
  size?: number;
};

function SilverMedal(props: props) {
  const { size = 40, ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M32.827 26.38l3.344-2.59a.4.4 0 00-.055-.676c-1.328-.678-4.895-2.352-8.972-3.039a.41.41 0 00-.477.408v9.137c0 .206.15.38.353.415 3.364.565 6.53 1.843 8.267 2.632.387.175.798-.28.576-.641l-3.136-5.099a.417.417 0 01.1-.548z"
        fill="#086FE9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.633 26.81l3.048-2.36c.694-.538.65-1.65-.186-2.078-1.358-.693-5.013-2.411-9.213-3.118-.77-.13-1.449.47-1.449 1.229v9.137c0 .62.453 1.136 1.049 1.237 3.26.548 6.351 1.792 8.06 2.568.587.267 1.163.032 1.48-.32.319-.353.493-.958.15-1.516l-2.94-4.78zm2.538-3.02l-3.344 2.59a.417.417 0 00-.1.547l3.135 5.099c.223.362-.188.816-.575.64-1.737-.788-4.903-2.066-8.267-2.631a.422.422 0 01-.353-.415v-9.137a.41.41 0 01.476-.408c4.078.687 7.645 2.361 8.972 3.04a.4.4 0 01.056.675z"
        fill="#023E84"
      />
      <path
        d="M33.75 24.751c0-2.975-.476-4.467-.702-5.007a.858.858 0 00-.335-.398c-.62-.39-2.547-1.311-5.764-.274a.41.41 0 00-.282.39v9.711c0 .275.262.474.53.416 2.232-.487 4.082-.121 5.037.163.406.12.848-.036.975-.44.237-.755.541-2.185.541-4.561z"
        fill="#0355B6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.583 24.75c0-3.062-.49-4.668-.766-5.327a1.692 1.692 0 00-.66-.782c-.798-.503-2.98-1.486-6.464-.362-.52.168-.86.653-.86 1.183v9.711c0 .84.787 1.394 1.542 1.23a9.402 9.402 0 014.621.147c.701.209 1.704-.022 2.008-.989.266-.848.58-2.364.58-4.81zm-1.535-5.006c.225.54.702 2.032.702 5.007 0 2.375-.304 3.806-.541 4.56-.127.405-.57.561-.976.44a10.232 10.232 0 00-5.035-.163.434.434 0 01-.531-.415v-9.71a.41.41 0 01.281-.391c3.218-1.037 5.145-.116 5.765.274a.858.858 0 01.335.398z"
        fill="#023E84"
      />
      <path
        d="M7.173 26.38L3.83 23.79a.4.4 0 01.055-.676c1.328-.678 4.895-2.352 8.973-3.039a.41.41 0 01.476.408v9.137c0 .206-.15.38-.353.415-3.364.565-6.53 1.843-8.267 2.632-.387.175-.798-.28-.575-.641l3.135-5.099a.417.417 0 00-.1-.548z"
        fill="#086FE9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.367 26.81L3.32 24.45c-.694-.538-.65-1.65.186-2.078 1.358-.693 5.013-2.411 9.213-3.118.77-.13 1.449.47 1.449 1.229v9.137c0 .62-.453 1.136-1.049 1.237-3.26.548-6.351 1.792-8.06 2.568-.587.267-1.163.032-1.48-.32-.319-.353-.493-.958-.15-1.516l2.94-4.78zM3.83 23.79l3.344 2.59c.169.13.212.366.1.547l-3.135 5.099c-.223.362.188.816.575.64 1.737-.788 4.903-2.066 8.267-2.631a.422.422 0 00.353-.415v-9.137a.41.41 0 00-.476-.408c-4.078.687-7.645 2.361-8.973 3.04a.4.4 0 00-.055.675z"
        fill="#023E84"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.966 29.275a.417.417 0 01-.366.462c-1.42.165-2.967.566-4.285 1.004-1.326.44-2.384.903-2.839 1.178a.417.417 0 11-.431-.713c.535-.324 1.664-.81 3.007-1.256 1.351-.448 2.956-.867 4.452-1.04a.417.417 0 01.462.365z"
        fill="#3D96FF"
      />
      <path
        d="M6.25 24.751c0-2.975.476-4.467.702-5.007a.858.858 0 01.335-.398c.62-.39 2.547-1.311 5.765-.274a.41.41 0 01.281.39v9.711a.434.434 0 01-.53.416 10.234 10.234 0 00-5.036.163c-.407.12-.849-.036-.976-.44-.237-.755-.541-2.185-.541-4.561z"
        fill="#0355B6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.417 24.75c0-3.062.49-4.668.766-5.327.134-.321.362-.594.66-.782.798-.503 2.98-1.486 6.464-.362.52.168.86.653.86 1.183v9.711c0 .84-.787 1.394-1.542 1.23a9.402 9.402 0 00-4.621.147c-.701.209-1.704-.022-2.008-.989-.266-.848-.58-2.364-.58-4.81zm1.535-5.006c-.226.54-.702 2.032-.702 5.007 0 2.375.304 3.806.541 4.56.127.405.57.561.976.44.955-.283 2.804-.65 5.035-.163a.434.434 0 00.531-.415v-9.71a.41.41 0 00-.281-.391c-3.218-1.037-5.145-.116-5.765.274a.858.858 0 00-.335.398z"
        fill="#023E84"
      />
      <path
        d="M6.667 24.583c0-2.095.344-3.76.538-4.532.077-.307.256-.574.519-.751 1.195-.806 5.165-3.05 12.276-3.05 7.07 0 11.035 2.218 12.255 3.036.276.184.462.467.538.79.193.816.54 2.553.54 4.507 0 1.52-.21 2.91-.394 3.835-.113.57-.782.82-1.29.54-1.756-.965-5.744-2.708-11.649-2.708-5.939 0-9.938 1.763-11.679 2.724-.5.276-1.152.04-1.266-.52a19.533 19.533 0 01-.388-3.87z"
        fill="#086FE9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.833 24.583c0-2.183.358-3.918.563-4.735.124-.494.418-.94.862-1.24C8.572 17.724 12.7 15.418 20 15.418c7.256 0 11.38 2.28 12.719 3.176.464.31.764.78.885 1.291l-.811.192a1.302 1.302 0 00-.538-.79C31.035 18.468 27.07 16.25 20 16.25c-7.112 0-11.081 2.244-12.277 3.05a1.268 1.268 0 00-.518.75 19.1 19.1 0 00-.538 4.533c0 1.619.205 2.98.388 3.872.114.56.766.796 1.266.52 1.74-.962 5.74-2.725 11.679-2.725 5.905 0 9.892 1.743 11.649 2.708.508.28 1.177.03 1.29-.54a20.09 20.09 0 00.394-3.835c0-1.954-.347-3.69-.54-4.507l.81-.192c.202.85.564 2.656.564 4.7 0 1.591-.22 3.037-.41 3.997-.243 1.217-1.596 1.61-2.51 1.107-1.66-.913-5.511-2.605-11.247-2.605-5.77 0-9.631 1.712-11.276 2.62-.897.496-2.238.131-2.486-1.08a20.364 20.364 0 01-.405-4.04z"
        fill="#023E84"
      />
      <path
        d="M29.962 20c0 .27-.214.488-.477.488A.482.482 0 0129.01 20c0-.27.213-.488.476-.488s.477.219.477.488zM27.54 18.384a.391.391 0 01-.387.396.391.391 0 01-.386-.396c0-.219.173-.396.386-.396.213 0 .386.177.386.396zM27.951 20a.6.6 0 01-.592.607.6.6 0 01-.592-.607.6.6 0 01.592-.607.6.6 0 01.592.607zM29.56 21.889a.6.6 0 01-.592.607.6.6 0 01-.593-.607.6.6 0 01.593-.607.6.6 0 01.592.607zM30.787 21.585a.3.3 0 01-.296.304.3.3 0 01-.296-.304.3.3 0 01.296-.303.3.3 0 01.296.303zM27.655 22.109a.3.3 0 01-.296.303.3.3 0 01-.296-.303.3.3 0 01.296-.304.3.3 0 01.296.304zM25.927 18.292a.3.3 0 01-.296.303.3.3 0 01-.296-.303.3.3 0 01.296-.304.3.3 0 01.296.304zM29.01 18.78a.3.3 0 01-.297.303.3.3 0 01-.296-.303.3.3 0 01.296-.304.3.3 0 01.296.304zM30.491 23.704a.3.3 0 01-.296.303.3.3 0 01-.296-.303.3.3 0 01.296-.303.3.3 0 01.296.303zM28.968 24.008a.3.3 0 01-.296.303.3.3 0 01-.297-.303.3.3 0 01.297-.304.3.3 0 01.296.303zM32.403 26.077a.3.3 0 01-.297.303.3.3 0 01-.296-.303.3.3 0 01.296-.304.3.3 0 01.297.304zM31.272 20.038a.3.3 0 01-.296.303.3.3 0 01-.296-.303.3.3 0 01.296-.304.3.3 0 01.296.304zM31.92 22.907a.488.488 0 01-.481.493.488.488 0 01-.482-.493c0-.273.215-.494.482-.494.266 0 .482.22.482.494zM32.403 20.788a.488.488 0 01-.482.494.488.488 0 01-.482-.494c0-.273.215-.494.482-.494.266 0 .482.221.482.494zM31.455 25.109a.488.488 0 01-.482.494.488.488 0 01-.482-.494c0-.273.216-.494.482-.494s.482.22.482.494zM32.549 24.311a.3.3 0 01-.296.304.3.3 0 01-.297-.304.3.3 0 01.297-.303.3.3 0 01.296.303z"
        fill="#055EC8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16 19.972c.217.076.33.314.254.531-.351.995-.482 2.417-.492 3.826a29.06 29.06 0 00.134 2.949c4.317-2.05 14.21-4.519 23.54.268a.417.417 0 01-.38.742c-9.422-4.834-19.48-2.013-23.314-.002a.417.417 0 01-.607-.317 29.056 29.056 0 01-.206-3.646c.01-1.429.14-2.966.539-4.097a.417.417 0 01.532-.254z"
        fill="#3D96FF"
      />
      <path
        d="M23.736 8.48c1.728-.253 3.229.075 4.219 1.065.99.99 1.318 2.492 1.065 4.219 1.4 1.042 2.23 2.335 2.23 3.736 0 1.4-.83 2.694-2.23 3.736.253 1.728-.075 3.229-1.065 4.219-.99.99-2.491 1.318-4.219 1.065-1.042 1.4-2.335 2.23-3.736 2.23-1.4 0-2.694-.83-3.736-2.23-1.727.253-3.228-.075-4.219-1.065-.99-.99-1.318-2.491-1.065-4.219-1.4-1.042-2.23-2.335-2.23-3.736 0-1.4.83-2.694 2.23-3.736-.253-1.727.075-3.228 1.065-4.219.99-.99 2.491-1.318 4.219-1.065 1.042-1.4 2.335-2.23 3.736-2.23 1.4 0 2.694.83 3.736 2.23z"
        fill="#BCCCD2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.102 7.598c1.702-.154 3.308.223 4.442 1.358 1.135 1.135 1.512 2.74 1.358 4.443 1.313 1.095 2.181 2.496 2.181 4.101s-.868 3.006-2.181 4.102c.154 1.702-.223 3.308-1.358 4.442-1.134 1.135-2.74 1.512-4.442 1.358-1.096 1.313-2.497 2.181-4.102 2.181-1.605 0-3.006-.868-4.101-2.18-1.703.153-3.308-.224-4.443-1.359-1.135-1.134-1.512-2.74-1.358-4.442-1.313-1.096-2.181-2.497-2.181-4.102 0-1.605.869-3.006 2.181-4.101-.154-1.703.223-3.308 1.358-4.443 1.135-1.135 2.74-1.512 4.443-1.358C16.994 6.285 18.395 5.417 20 5.417s3.006.868 4.102 2.181zm-.366.882C22.694 7.08 21.401 6.25 20 6.25c-1.4 0-2.694.83-3.736 2.23-1.727-.253-3.228.075-4.219 1.065-.99.99-1.318 2.492-1.065 4.219-1.4 1.042-2.23 2.335-2.23 3.736 0 1.4.83 2.694 2.23 3.736-.253 1.728.075 3.229 1.065 4.22.99.99 2.492 1.317 4.22 1.064 1.041 1.4 2.334 2.23 3.735 2.23 1.4 0 2.694-.83 3.736-2.23 1.728.253 3.229-.075 4.219-1.065.99-.99 1.318-2.491 1.065-4.219 1.4-1.042 2.23-2.335 2.23-3.736 0-1.4-.83-2.694-2.23-3.736.253-1.727-.075-3.228-1.065-4.219-.99-.99-2.491-1.318-4.219-1.065z"
        fill="#81A5B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.417 17.083c.23 0 .416.187.416.417 0 1.403-.79 2.654-2.037 3.643.182 1.581-.144 3.025-1.136 4.017-.992.992-2.436 1.318-4.017 1.136-.989 1.247-2.24 2.037-3.643 2.037a.417.417 0 110-.833c1.119 0 2.206-.662 3.125-1.897a.417.417 0 01.395-.164c1.523.224 2.76-.077 3.551-.868.791-.791 1.092-2.028.868-3.551a.417.417 0 01.164-.395C29.338 19.706 30 18.619 30 17.5c0-.23.186-.417.417-.417z"
        fill="#A2B9C1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7.5c-1.119 0-2.206.662-3.125 1.897a.417.417 0 01-.395.164c-1.523-.224-2.76.077-3.551.868-.791.791-1.092 2.028-.868 3.551a.416.416 0 01-.164.395C10.662 15.294 10 16.381 10 17.5a.417.417 0 01-.833 0c0-1.403.79-2.654 2.037-3.643-.182-1.581.144-3.025 1.136-4.017.992-.992 2.436-1.318 4.017-1.136.989-1.247 2.24-2.037 3.643-2.037a.417.417 0 010 .833z"
        fill="#D0DEE3"
      />
      <path
        d="M22.768 10.819c1.28-.188 2.391.055 3.125.788.733.734.976 1.846.788 3.126 1.038.772 1.652 1.73 1.652 2.767 0 1.038-.614 1.995-1.652 2.767.188 1.28-.055 2.392-.788 3.126-.734.733-1.846.976-3.126.788-.771 1.038-1.73 1.652-2.767 1.652-1.038 0-1.995-.614-2.767-1.652-1.28.188-2.392-.055-3.126-.788-.733-.734-.976-1.846-.788-3.126-1.038-.771-1.652-1.73-1.652-2.767 0-1.038.614-1.995 1.652-2.767-.188-1.28.055-2.392.788-3.126.734-.733 1.846-.976 3.126-.788.772-1.038 1.73-1.652 2.767-1.652 1.038 0 1.996.614 2.768 1.652z"
        fill="#D2E2E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.107 23.393c-.733-.734-.976-1.846-.788-3.126-1.038-.772-1.652-1.73-1.652-2.767 0-1.038.614-1.995 1.652-2.768-.188-1.28.055-2.39.788-3.124.734-.734 1.846-.977 3.125-.79.772-1.037 1.73-1.651 2.768-1.651 1.038 0 1.995.614 2.767 1.652 1.28-.188 2.392.055 3.125.788.734.734.977 1.846.79 3.126 1.037.771 1.651 1.73 1.651 2.767 0 1.038-.614 1.995-1.652 2.767.188 1.28-.055 2.392-.789 3.126-.733.733-1.845.976-3.125.788-.772 1.038-1.73 1.652-2.767 1.652-1.038 0-1.995-.614-2.767-1.652-1.28.188-2.392-.055-3.126-.788zm2.762 1.666c-1.263.091-2.477-.203-3.35-1.077-.875-.874-1.17-2.088-1.078-3.351-.958-.828-1.608-1.895-1.608-3.131 0-1.236.65-2.303 1.608-3.131-.091-1.263.203-2.477 1.077-3.35.874-.875 2.088-1.17 3.35-1.078.83-.958 1.896-1.608 3.132-1.608 1.236 0 2.303.65 3.131 1.608 1.263-.091 2.477.203 3.35 1.077.875.874 1.17 2.088 1.078 3.35.958.83 1.608 1.896 1.608 3.132 0 1.236-.65 2.303-1.608 3.131.091 1.263-.203 2.477-1.077 3.35-.874.875-2.088 1.17-3.351 1.078-.829.958-1.895 1.608-3.131 1.608-1.236 0-2.303-.65-3.131-1.608z"
        fill="#81A5B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 17.083c.23 0 .417.187.417.417 0 1.038-.575 1.954-1.461 2.674.118 1.136-.124 2.19-.858 2.924s-1.788.976-2.924.858c-.72.887-1.636 1.46-2.674 1.46a.417.417 0 110-.833c.756 0 1.508-.447 2.156-1.319a.417.417 0 01.395-.163c1.075.158 1.923-.058 2.458-.592.534-.535.75-1.383.592-2.458a.416.416 0 01.163-.395c.872-.648 1.32-1.4 1.32-2.156 0-.23.186-.417.416-.417z"
        fill="#BAD0D7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10.417c-.756 0-1.508.447-2.157 1.319a.417.417 0 01-.394.163c-1.076-.158-1.923.058-2.458.592-.534.535-.75 1.383-.592 2.458a.417.417 0 01-.163.395c-.872.648-1.32 1.4-1.32 2.156a.417.417 0 01-.833 0c0-1.038.574-1.954 1.461-2.674-.118-1.136.124-2.19.858-2.924s1.788-.976 2.924-.858c.72-.886 1.636-1.46 2.674-1.46a.417.417 0 010 .833z"
        fill="#E1F4FA"
      />
    </svg>
  );
}

export default SilverMedal;
