import { Box, Container, Flex } from "@chakra-ui/layout";

import Logo from "../common/Logo";
import UserMenu from "../common/UserMenu";

type props = {
  isDark?: boolean;
};

function Header(props: props) {
  const { isDark = false } = props;
  return (
    <Box
      as="header"
      mb="100px"
      backgroundColor={isDark ? "#131723" : "transparent"}
    >
      <Container maxW="container.xl">
        <Flex
          as="nav"
          justifyContent="space-between"
          alignItems="center"
          height="72px"
        >
          <Logo />
          <UserMenu />
        </Flex>
      </Container>
    </Box>
  );
}

export default Header;
