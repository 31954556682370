import LevelOne from "../../../icons/christmass-campaign/LevelOne";
import LevelTwo from "../../../icons/christmass-campaign/LevelTwo";
import LevelThree from "../../../icons/christmass-campaign/LevelThree";
import LevelFour from "../../../icons/christmass-campaign/LevelFour";
import LevelFive from "../../../icons/christmass-campaign/LevelFive";
import LevelSix from "../../../icons/christmass-campaign/LevelSix";
import LevelSeven from "../../../icons/christmass-campaign/LevelSeven";
import EBronzeMedal from "../../../icons/easter-campaign/BronzeMedal";
import ESilverMedal from "../../../icons/easter-campaign/SilverMedal";
import EGoldMedal from "../../../icons/easter-campaign/GoldMedal";
import EPlatinumMedal from "../../../icons/easter-campaign/PlatinumMedal";
import EDiamondMedal from "../../../icons/easter-campaign/DiamondMedal";
import BronzeMedal from "../../../icons/BronzeMedal";
import SilverMedal from "../../../icons/SilverMedal";
import GoldMedal from "../../../icons/GoldMedal";
import PlatinumMedal from "../../../icons/PlatinumMedal";
import DiamondMedal from "../../../icons/DiamondMedal";
import constants from "../../../constants";

const BADGE_TIERS = [
  {
    id: "bronze",
    icon: constants.IS_EASTER_CAMPAIGN ? EBronzeMedal : BronzeMedal,
    amount: constants.IS_EASTER_CAMPAIGN ? 1000 : 500,
  },
  {
    id: "silver",
    icon: constants.IS_EASTER_CAMPAIGN ? ESilverMedal : SilverMedal,
    amount: constants.IS_EASTER_CAMPAIGN ? 5000 : 2500,
  },
  {
    id: "gold",
    icon: constants.IS_EASTER_CAMPAIGN ? EGoldMedal : GoldMedal,
    amount: constants.IS_EASTER_CAMPAIGN ? 15000 : 10000,
  },
  {
    id: "platinum",
    icon: constants.IS_EASTER_CAMPAIGN ? EPlatinumMedal : PlatinumMedal,
    amount: constants.IS_EASTER_CAMPAIGN ? 30000 : 25000,
  },
  {
    id: "diamond",
    icon: constants.IS_EASTER_CAMPAIGN ? EDiamondMedal : DiamondMedal,
    amount: constants.IS_EASTER_CAMPAIGN ? 65000 : 50000,
  },
];

const LEVEL_TIERS = [
  {
    id: "level-one",
    icon: LevelOne,
    amount: 1000,
  },
  {
    id: "level-two",
    icon: LevelTwo,
    amount: 5000,
  },
  {
    id: "level-three",
    icon: LevelThree,
    amount: 15000,
  },
  {
    id: "level-four",
    icon: LevelFour,
    amount: 30000,
  },
  {
    id: "level-five",
    icon: LevelFive,
    amount: 60000,
  },
  {
    id: "level-six",
    icon: LevelSix,
    amount: 100000,
  },
  {
    id: "level-seven",
    icon: LevelSeven,
    amount: 250000,
  },
];

export const TIERS = constants.IS_CHRISTMAS_CAMPAIGN
  ? LEVEL_TIERS
  : BADGE_TIERS;
