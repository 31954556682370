import { Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import LogoIcon from "../../icons/Logo";

type props = {
  size?: number;
};

function Logo(props: props) {
  const { size = 48 } = props;
  return (
    <Link to="/">
      <Flex alignItems="center">
        <LogoIcon size={size} color="#fff" />
        <Text ml="16px" textTransform="uppercase" color="#fff">
          Naga
        </Text>
      </Flex>
    </Link>
  );
}

export default Logo;
