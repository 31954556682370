import React from "react";
import {
  Flex,
  Box,
  Heading,
  Container,
  Text,
  Grid,
  Image,
} from "@chakra-ui/react";
import { ArrowDownCircle } from "react-feather";
import { map, last, find, isNil } from "ramda";
import Particles from "react-tsparticles";
import { useQuery } from "react-query";
import { useTranslation, Trans } from "react-i18next";
import { useIntercom } from "react-use-intercom";

import Progress from "./Progress";
import Gift from "./Gift";
import Tiers from "./Tiers";
import Header from "./Header";
import { UserInfoOutput } from "../../api/apiTypes";
import {
  getIntercomConfig,
  encodeURIComponentAdvanced,
} from "../../helpers/helper-functions";
import constants from "../../constants";
import api from "../../api";

const gradient = "linear-gradient(182.33deg, #013472 26.94%, #FFFFFF 88.7%)";

function Store() {
  const { t } = useTranslation();
  const { boot } = useIntercom();

  const progressRef = React.useRef<HTMLDivElement>(null);
  const { data } = useQuery(constants.QUERY_NAMES.loyaltyBalance, () =>
    api.queries.loyaltyBalance()
  );
  const unlockedGroups = data?.data.data.unlocked_groups || [];
  const loyaltyGroupsQuery = useQuery(
    constants.QUERY_NAMES.loyaltyGroups,
    () => api.queries.loyaltyGroups(),
    {
      enabled: false,
    }
  );
  const groupId =
    last(unlockedGroups)?.group_id ||
    loyaltyGroupsQuery.data?.data.data[0].group_id;
  const products = find(
    (item) => item.group_id === groupId,
    loyaltyGroupsQuery.data?.data.data || []
  )?.available_products;

  function handleOnArrowDownClick() {
    if (progressRef.current) {
      progressRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  useQuery<UserInfoOutput>(
    constants.QUERY_NAMES.userInfo,
    () => api.queries.userInfo(),
    {
      onSuccess(response: UserInfoOutput) {
        const config = getIntercomConfig(response);
        boot(config);
      },
    }
  );

  return (
    <Flex flexDir="column">
      {!constants.IS_EASTER_CAMPAIGN && (
        <Particles
          options={{
            particles: {
              move: {
                enable: true,
                direction: "bottom",
                outMode: "out",
              },
            },
          }}
        />
      )}
      <Box bg={gradient}>
        <Header />
        <Container maxW="container.xl" color="#fff" mb="200px">
          <Grid gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}>
            <Box>
              <Heading mb="16px" fontSize="xxx-large" fontWeight="bold">
                {t("STORE.WELCOME_TO_NAGA_LOYALTY_STORE")}
              </Heading>
              <Text mb="16px" fontSize="x-large">
                {t("STORE.GET_LUXARY_PRIZES_WITH_BIGGER_NAGA_DEPOSITS")}
              </Text>
              <ArrowDownCircle
                onClick={handleOnArrowDownClick}
                size={40}
                cursor="pointer"
              />
            </Box>
            <Image src={constants.GIFT_IMG} alt="" />
          </Grid>
        </Container>
        <Container maxW="container.xl" mb="86px" ref={progressRef}>
          <Progress currentBalance={data?.data.data.current_balance || 0} />
        </Container>
      </Box>
      <Container maxW="container.xl">
        <Container maxW="container.lg" mb="86px">
          <Heading textAlign="center">
            {t("STORE.CONGRATS_YOU_ARE_QUALIFIED_FOR_ONE_OF_THESE_GIFTS_V2")} 🎁
          </Heading>
        </Container>
        <Grid
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
          gridGap="24px"
          mb="86px"
        >
          {map(
            (item) => (
              <Gift
                key={item.product_id}
                id={item.product_id}
                claimedCount={item.total_redeemed}
                backgroundImage={`${
                  constants.ASSETS_BUCKET_URL
                }/${encodeURIComponentAdvanced(item.image_url || "")}`}
                title={item.product_name}
                isClaimed={!isNil(item.buy_date)}
                isMissingPoints={false}
              />
            ),
            products || []
          )}
        </Grid>
        <Text color="#454545" textAlign="center" mb="40px">
          *
          <Trans
            i18nKey="STORE.YOU_CAN_ONLY_CHOOSE_THREE_GIFTS"
            t={t}
            components={{
              terms: (
                <a
                  style={{ color: "#3179FF" }}
                  href={constants.TERMS_AND_CONDITIONS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("STORE.TERMS_AND_CONDITIONS")}
                </a>
              ),
            }}
          />
        </Text>
      </Container>
      <Tiers />
    </Flex>
  );
}

export default Store;
