import axios from "../axios";

import {
  UserInfoOutput,
  LoyaltyGroupsOutput,
  LoyaltyProductOutput,
  LoyaltyBalanceOutput,
  AutologinUrlInput,
  AutologinUrlOutput,
  CountryOutput,
} from "./apiTypes";

function userInfo(): Promise<UserInfoOutput> {
  return axios.get("/user/info");
}

function loyaltyGroups(): Promise<LoyaltyGroupsOutput> {
  return axios.get("/store/groups");
}

function loyaltyProduct(id: number): Promise<LoyaltyProductOutput> {
  return axios.get(`/store/product/${id}`);
}

function loyaltyBalance(): Promise<LoyaltyBalanceOutput> {
  return axios.get(`/store/balance`);
}

function autologinUrl(data: AutologinUrlInput): Promise<AutologinUrlOutput> {
  const { dest, page } = data;
  return axios.get(`/application/autologin/url`, {
    params: {
      dest,
      page,
    },
  });
}

function allCountries(): Promise<CountryOutput> {
  return axios.get("/country/all");
}

function excludedDeliveryCountries(id: number): Promise<CountryOutput> {
  return axios.get(`/store/product/excluded_delivery_countries/${id}`);
}

const EXPORTS = {
  userInfo,
  loyaltyGroups,
  loyaltyProduct,
  loyaltyBalance,
  autologinUrl,
  allCountries,
  excludedDeliveryCountries,
};

export default EXPORTS;
