import { ButtonGroup, Button } from "@chakra-ui/button";
import { Wrap, WrapItem } from "@chakra-ui/layout";
import * as R from "ramda";

type PROPERTY = {
  attribute_name: string;
  attribute_id: number;
};
type props = {
  property: PROPERTY[];
  active: number | null;
  onClick: (attribute_id: number) => void;
};

function ItemProperties(props: props) {
  const { property, active, onClick } = props;
  return (
    <ButtonGroup>
      <Wrap>
        {R.map(
          (item) => (
            <WrapItem key={item.attribute_id}>
              <Button
                isActive={R.equals(item.attribute_id, active)}
                onClick={() => onClick(item.attribute_id)}
              >
                {item.attribute_name}
              </Button>
            </WrapItem>
          ),
          property
        )}
      </Wrap>
    </ButtonGroup>
  );
}

export default ItemProperties;
