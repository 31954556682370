import { Box, Text, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type props = {
  id: number;
  claimedCount: number;
  backgroundImage: string;
  title: string;
  isClaimed: boolean;
  isMissingPoints: boolean;
};

function Gift(props: props) {
  const {
    id,
    backgroundImage,
    title,
    isClaimed,
    isMissingPoints,
  } = props;
  const { t } = useTranslation();

  function getButtonText() {
    if (isMissingPoints) {
      return t("STORE_DETAILS.NOT_ENOUGH_POINTS");
    }
    if (isClaimed) {
      return t("STORE.ALREADY_CLAIMED");
    }
    return t("STORE.CLAIM_NOW");
  }

  return (
    <Box position="relative">
      <Box
        backgroundImage={backgroundImage}
        backgroundColor="#fff"
        backgroundSize="cover"
        backgroundPosition="center"
        height="314px"
        borderRadius="lg"
        padding="12px"
        mb="16px"
      />
      <Text fontWeight="bold" mb="16px">
        {title}
      </Text>
      <Link to={`/store/${id}`}>
        <Button
          as={motion.button}
          whileHover={{
            scale: 1.1,
          }}
          colorScheme="blue"
          width={["100%", "auto"]}
        >
          {getButtonText()}
        </Button>
      </Link>
    </Box>
  );
}

export default Gift;
